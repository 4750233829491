import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useStyles } from './approvaldetailstyle.js';
import PageContent from 'src/components/Page';
import EventEmitter from 'src/utils/EventEmitter';
import {
  AppBar,
  Tabs,
  Tab,
  Box
} from '@material-ui/core';
import AllAwaitingApproval from './AwaitingApproval/AllAwaitingApproval.js';
import AllApprovalHistory from './ApprovalHistory/AllApprovalHistory.js';
import SelectedAwaitingApproval from './AwaitingApproval/SelectedAwaitingApproval.js';
import SelectedApprovalHistory from './ApprovalHistory/SelectedApprovalHistory.js';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Approvals = ({ type }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [initialized, setInitialized] = useState(false);
  const [value, setValue] = React.useState(0);
  
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      
      if (type === 'approvals') {
        setValue(0);
        EventEmitter.emit('reload_item', { value: '/approvals/:id' });
      } else if (type === 'approvals-history') {
        setValue(1);
        EventEmitter.emit('reload_item', { value: '/approvals-history/:id' });
      }
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      navigate(`/approvals`);
      EventEmitter.emit('reload_item', { value: '/approvals/:id' });
    } else {
      navigate(`/approvals-history`);
      EventEmitter.emit('reload_item', { value: '/approvals-history/:id' });
    }
  };

  return (
    <PageContent title="Approvals">
      <div className={classes.container}>
        <div className={classes.leftSide}>
          <AppBar position="static" className={classes.Appbar} style={{ marginTop: '19px', marginBottom: '5px' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab className={classes.customfontstyle} label="Awaiting Approval" {...a11yProps(0)} />
                <Tab className={classes.customfontstyle} label="Approval History" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} className={classes.tab}>
              <AllAwaitingApproval />
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tab}>
              <AllApprovalHistory />
            </TabPanel>
        </div>

        <div className={classes.rightSide}>
          {value === 0 ?
            <SelectedAwaitingApproval/>
          :
            <SelectedApprovalHistory/>
          }
        </div>
      </div>
    </PageContent>
  );
};

export default Approvals;
