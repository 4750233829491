import React from 'react';
import { makeStyles, withStyles, Button, Menu } from "@material-ui/core";
import { styled } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { createTheme } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export const getStatusIconPath = status => {
  let statusIconPath = '';

  switch (status) {
    case 'draft':
      statusIconPath = "/static/images/icons/draft.svg";
      break;
    case 'completed':
      statusIconPath = "/static/images/icons/complete.svg";
      break;
    case 'sent':
      statusIconPath = "/static/images/icons/sent.svg";
      break;
    case 'cancelled':
      statusIconPath = "/static/images/icons/cancelled.svg";
      break;
    case 'awaiting_approval':
      statusIconPath = "/static/images/icons/awaiting_approval.svg";
      break;
    case 'rejected':
      statusIconPath = "/static/images/icons/rejected.svg";
      break;
    default:
      statusIconPath = "";
  }

  return statusIconPath;
}

export const theme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#67AC2D',
      },
    },
    MuiPickersDay: {
      day: {
        color: '#000',
      },
      daySelected: {
        backgroundColor: '#67AC2D',
        '&:hover': {
          backgroundColor: '#92cf5e',
        },
      },
      current: {
        color: '#67AC2D',
      },
    },
    MuiPickersYear: {
      yearSelected: {
        color: '#67AC2D',
        '&:hover': {
          color: '#67AC2D !important',
        },
      },
    },
    MuiIconButton: {
      root: {
        color: '#67AC2D',
        padding: '0px',
      },
    },
    MuiOutlinedInput: {
      root: {
        height: '34px',
        borderRadius: '6px',
        fontSize: '15px !important',
        fontWeight: '400 !important',
        letterSpacing: '-0.4px !important',
        color: 'rgba(60, 60, 67, 0.6) !important',
        borderColor: 'red',
        '& fieldset': {
          borderColor: '#B9B9BB',
          transition: 'border-color 0.3s ease',
        },
        '&:hover fieldset': {
          borderColor: '#82CB43 !important',
        },
        '&:focus-within fieldset': { 
          borderColor: '#82CB43 !important',
        },
      },
      input: {
        textAlign: 'right',
        padding: '0px 16px',
      },
    },
    MuiButton: {
      textPrimary: {
        color: '#67AC2D',
      },
      textSecondary: {
        color: '#67AC2D',
      },
    },
    MuiTextField: {
      root: {
        width: '100%',
      },
    },
  },
});

export const CustomButton = styled(Button)({
  fontWeight: 400,
  fontSize: '15px',
  boxShadow: 'none',
  lineHeight: '20px',
  borderRadius: '40px',
  padding: '0px 14px',
  textTransform: 'none',
  height: '34px',
  letterSpacing: '-0.4px',
  color: 'rgba(55, 67, 85, 1)',
  backgroundColor: 'rgba(81, 101, 115, 0.15)',
  fontFamily: [
    'SF Pro',
    'Arial',
    'sans-serif',
  ].join(','),
  '&:hover': {
    backgroundColor: 'rgba(81, 101, 115, 0.3)',
    boxShadow: 'none',
  },
  '& .MuiButton-startIcon': {
    marginRight: '4px !important'
  }
});

export const CustomButton2 = styled(Button)({
  fontWeight: 400,
  fontSize: '15px',
  boxShadow: 'none',
  lineHeight: '20px',
  borderRadius: '40px',
  padding: '0px 14px',
  textTransform: 'none',
  height: '34px',
  letterSpacing: '-0.4px',
  color: 'white',
  backgroundColor: 'rgb(103, 172, 45)',
  fontFamily: [
    'SF Pro',
    'Arial',
    'sans-serif',
  ].join(','),
  '&:hover': {
    backgroundColor: 'rgb(80, 134, 36)',
    boxShadow: 'none',
  },
  '& .MuiButton-startIcon': {
    marginRight: '4px !important'
  }
});

export const DeleteButton = styled(Button)({
  fontWeight: 400,
  fontSize: '15px',
  boxShadow: 'none',
  lineHeight: '20px',
  borderRadius: '40px',
  padding: '0px 14px',
  textTransform: 'none',
  height: '34px',
  letterSpacing: '-0.4px',
  color: 'white',
  backgroundColor: '#E87777',
  fontFamily: [
    'SF Pro',
    'Arial',
    'sans-serif',
  ].join(','),
  '&:hover': {
    backgroundColor: '#cd6666',
    boxShadow: 'none',
  },
  '& .MuiButton-startIcon': {
    marginRight: '4px !important'
  }
});

export const StyledMenu = withStyles({
  paper: {
    width: '200px',
    background: '#F6F6F6',
    boxShadow: '0px 0px 32px 0px rgba(0, 0, 0, 0.2)',
    borderRadius: '12px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    {...props}
  />
));

export const LoadingButtonCustom = styled(LoadingButton)(({ theme }) => ({
  width: '100%',
  borderRadius: '10px',
  height: '44px',
  boxShadow: 'none',
  color: 'rgba(60, 60, 67, 0.9)',
  borderColor: 'rgba(60, 60, 67, 0.6)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderColor: 'rgba(60, 60, 67, 0.6)',
  },
  '& .MuiLoadingButton-loadingIndicator': {
    right: '118px !important'
  }
}));

export const CancelButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#e0e0e0de',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#bdbdbdde',
    },
  },
})(Button);

export const SubmitButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#2AC940',
    color: '#ffffff',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#22a033',
    },
  },
})(Button);

export const AddItemButton = withStyles({
  root: {
    border: 'none',
    backgroundColor: 'rgba(81, 101, 115, 0.15)',
    padding: '7px 14px !important',
    textTransform: 'capitalize',
    color: '#37455',
    borderRadius: '12px',
    '&:hover': {
      border: 'none',
      backgroundColor: 'rgba(81, 101, 115, 0.15)',
      borderRadius: '12px',

    },
  },
})(Button);

export const CompletedButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#7cd183',
    color: '#ffffff',
    height: '30px',
    width: '150px',
    '&:hover': {
      backgroundColor: '#6abd7c'
    }
  }
})(Button);