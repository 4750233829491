import React, { useState, useEffect } from 'react';
import {
  withStyles,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  TextField,
  Typography,
  Grid,
} from '@material-ui/core';
import { SubmitButton } from 'src/components/ReusableStyle';
import directus from '../../services/directus';
import { useStylesEmployees } from './style.js';
import useStateRef from "react-usestateref";
import { useSnackbar } from 'notistack';
import ErrorMessage from 'src/views/Components/ErrorMessage';

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderColor: 'red',
      backgroundColor: 'white',
      borderRadius: '6px',
      marginBottom: '6px',
      marginRight: '6px',
      height: '30px'
    },
  },
})(TextField);

const CompanySettings = (props) => {
  /* eslint-disable no-unused-vars*/
  const classes = useStylesEmployees();
  const [initialized, setInitialized] = useState(false);

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [dataConfig, setDataConfig, dataConfigRef] = useStateRef(null);

  const { enqueueSnackbar } = useSnackbar();
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadCompanySettings();
    }
  });
  /* eslint-enable react-hooks/exhaustive-deps*/

  const loadCompanySettings = async () => {
    try {
      var result = await directus.getItems('company_settings');

      setDataConfig(result.data[0])
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  };

  const handleChangeInput = (e, type) => {
    const { name, value } = e.target;
    setDataConfig({ ...dataConfigRef.current, [name]: value })
  }

  const handleUpdate = async () => {
    setLoadingUpdate(true);
    try {
      var data = dataConfigRef.current;
      const body = {
        id: data.id,
        name: data.name,
        email: data.email,
        phone: data.phone,
        abn_number: data.abn_number,
        address_line_1: data.address_line_1,
        address_line_2: data.address_line_2,
        city: data.city,
        state: data.state,
        postcode: data.postcode,
      }

      await directus.updateItem('company_settings', body.id, body);

      enqueueSnackbar("Data updated successfully", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200 });
      loadCompanySettings();
      setLoadingUpdate(false);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  }

  return (
    <div >
      {dataConfigRef.current ? dataConfigRef.current.id ?
        <div >
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{ backgroundColor: 'white', paddingLeft: "20px" }}>
            <ListItem style={{ marginTop: "10px" }}>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h4">Profile</Typography></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Company Name</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField data-testid='Company Name' disabled={!props.config_settings} style={{ width: "100%" }} name="name" onChange={handleChangeInput} value={dataConfigRef.current.name ? dataConfigRef.current.name : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Company Email</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField data-testid='Company Email' disabled={!props.config_settings} style={{ width: "100%" }} name="email" onChange={handleChangeInput} value={dataConfigRef.current.email ? dataConfigRef.current.email : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Company Phone Number</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField data-testid='Company Phone Number' disabled={!props.config_settings} style={{ width: "100%" }} name="phone" onChange={handleChangeInput} value={dataConfigRef.current.phone ? dataConfigRef.current.phone : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>ABN Number</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField data-testid='ABN Number' disabled={!props.config_settings} style={{ width: "100%" }} name="abn_number" onChange={handleChangeInput} value={dataConfigRef.current.abn_number ? dataConfigRef.current.abn_number : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem style={{ marginTop: "20px" }}>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h4">Address Information</Typography></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Address Line 1</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField data-testid='Address Line 1' disabled={!props.config_settings} style={{ width: "100%" }} name="address_line_1" onChange={handleChangeInput} value={dataConfigRef.current.address_line_1 ? dataConfigRef.current.address_line_1 : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Address Line 2</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField data-testid='Address Line 2' disabled={!props.config_settings} style={{ width: "100%" }} name="address_line_2" onChange={handleChangeInput} value={dataConfigRef.current.address_line_2 ? dataConfigRef.current.address_line_2 : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>City</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField data-testid='City' disabled={!props.config_settings} style={{ width: "100%" }} name="city" onChange={handleChangeInput} value={dataConfigRef.current.city ? dataConfigRef.current.city : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>State</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField data-testid='State' disabled={!props.config_settings} style={{ width: "100%" }} name="state" onChange={handleChangeInput} value={dataConfigRef.current.state ? dataConfigRef.current.state : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Postcode</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField data-testid='Postcode' disabled={!props.config_settings} style={{ width: "100%" }} name="postcode" onChange={handleChangeInput} value={dataConfigRef.current.postcode ? dataConfigRef.current.postcode : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem style={{ marginBottom: "20px", height: "60px" }}>
              {!loadingUpdate ?
                <SubmitButton
                  id="save_btn"
                  size="small"
                  variant="contained"
                  onClick={() => handleUpdate()}
                  disabled={!props.config_settings}
                >
                  Update
                </SubmitButton>
                : <CircularProgress className={classes.circular} style={{ marginLeft: "0%" }} />}
            </ListItem>
          </List>
        </div>
        : <Typography variant="h5" style={{ fontWeight: "normal", textAlign: "center", marginTop: "20px" }}>No Company Settings</Typography> :
        <CircularProgress className={classes.circular} />
      }
    </div>
  );
}

export default CompanySettings;
