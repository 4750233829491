import directus from 'src/services/directus';
import EventEmitter from './EventEmitter';
import { getCache, setCache } from './KeyValueCache';
/**
 * Get current directus user
 * @param {boolean} forceReload
 */
export async function getUser(forceReload = false) {
  try {
    var user = JSON.parse(window.localStorage.getItem('directus_data'));
    if (forceReload) {
      var cache = getCache('directus_user');
      if (cache !== null) {
        return cache;
      }

      var result = await directus.getItem('directus_users', user.user.id, { fields: '*.*.*' });
      if (!result.error) {
        window.localStorage.setItem('directus_user', JSON.stringify(result.data));
        setCache('directus_user', result.data);
        return result.data;
      } else {
        window.localStorage.clear();
        window.location.reload();
      }
    }
  } catch (err) {}

  return JSON.parse(window.localStorage.getItem('directus_user'));
}

/**
 * Get current employee
 * @param {boolean} forceReload
 */
export async function getEmployee(forceReload = false) {
  try {
    var current_user = JSON.parse(window.localStorage.getItem('directus_employee'));
    if (forceReload) {
      const cache = getCache('directus_employee');
      if (cache !== null) {
        return cache;
      }
      
      var result = await directus.getItems('employees', { filter: { user: current_user.user.id }, fields: '*.*, user.id, user.role.*', single: 1 });

      if (!result.error) {
        window.localStorage.setItem('directus_employee', JSON.stringify(result.data));
        if (result.data.user.role.name !== current_user.user.role.name) {
          EventEmitter.emit('reload_profile', { value: result.data.user.role.name });
        }

        setCache('directus_employee', result.data);
        return result.data;
      } else {
        window.localStorage.clear();
        window.location.reload();
      }
    }
  } catch (err) {}

  return JSON.parse(window.localStorage.getItem('directus_employee'));
}

export function isAuthenticated() {
  return window.localStorage.getItem('directus_users') && window.localStorage.getItem('directus_employee');
}

export async function redirectLogin() {
  var user = JSON.parse(window.localStorage.getItem('directus_employee'));
  if (user !== null) {
    if (window.get_me_timer) clearTimeout(window.get_me_timer);

    window.get_me_timer = setTimeout(async () => {
      try {
        await directus.getMe();
      } catch (e) {
        window.localStorage.clear();
        window.location.reload();
      }

      window.get_me_timer = null;
    }, 3000);
  }
}
