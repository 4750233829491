import React, { useState, useEffect } from 'react';
import useStateRef from 'react-usestateref';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { getEmployee } from 'src/utils/sessions';
import EventEmitter from "src/utils/EventEmitter";
import ErrorMessage from 'src/components/ErrorMessage';
import directus from "../../services/directus";
import { CustomTooltip } from '../setting/style';
import Chip from '@material-ui/core/Chip';
import { useNavigate } from 'react-router-dom';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ClearIcon from '@mui/icons-material/Clear';
import {
  SubmitButton,
  CancelButton,
  CustomButton,
  CustomButton2,
  StyledMenu
} from 'src/components/ReusableStyle';
import { useStyles } from './style.js';
import { POApproverPermission, POCreatorPermission, AdminPermission } from 'src/utils/Permission';
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';
import {
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Button,
  withStyles,
  List,
  MenuItem,
  Grid,
  IconButton
} from "@material-ui/core";

const CancelledButton = withStyles({
  root: {
    borderRadius: '40px',
    backgroundColor: '#E87777',
    color: '#ffffff',
    height: '30px',
    width: '150px',
    '&:hover': {
      backgroundColor: '#f08984',
    },
  },
})(Button);

export const CompletedButton = withStyles({
  root: {
    borderRadius: '40px',
    backgroundColor: '#67AC2D',
    color: '#ffffff',
    height: '30px',
    width: '150px',
    '&:hover': {
      backgroundColor: '#77c337',
    },
  },
})(Button);

const SentButton = withStyles({
  root: {
    borderRadius: '40px',
    backgroundColor: '#f9ebcc',
    height: '30px',
    width: '150px',
    '&:hover': {
      backgroundColor: '#fde9b9',
    },
  },
})(Button);

const DraftButton = withStyles({
  root: {
    borderRadius: '40px',
    backgroundColor: '#8aa9ca',
    color: '#ffffff',
    height: '30px',
    width: '150px',
    '&:hover': {
      backgroundColor: '#6898cb',
    },
  },
})(Button);

const ButtonsView = ({
  selectedOrder,
  loadOrderSelected,
  disabledCheck,
  selectedSupplier,
  editSubmit,
  setDataCustomEmail,
  dataCustomEmail,
  customEmailLoading,
  setCustomEmailLoading,
  setIsCustomEmail,
  isCustomEmail,
  closeCustomEmail,
  editSave,
  loadingSubmit,
  delivery_address_check,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const navigate = useNavigate();
  const [initialized, setInitialized] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingDuplicate, setLoadingDuplicate] = useState(false);
  const [loadingUpdateStatus, setLoadingUpdateStatus] = useState(false);
  const [loadingStatusCompleted, setLoadingStatusCompleted] = useState(false);
  const [open, setOpen] = useState(false);
  const [loadingResendSupplier, setLoadingResendSupplier] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [anchorElOrdersType, setAnchorElOrdersType] = React.useState(null);
  const openActions = Boolean(anchorElOrdersType);
  const [openDeleteOrderDialog, setOpenDeleteOrderDialog] = React.useState(false);
  const [loadingDeleteDialog, setLoadingDeleteDialog] = useState(false);
  const [employeeID, setEmployeeID, employeeIDRef] = useStateRef(null);
  const [openGenerateInvoiceDialog, setOpenGenerateInvoiceDialog] = React.useState(false);
  const [loadingGenerateInvoice, setLoadingGenerateInvoice] = useState(false);
  const [selectedAttachment, setSelectedAttachment, selectedAttachmentRef] = useStateRef(null);
  const [loadingDeleteFile, setLoadingDeleteFile] = useState(false);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadEmployeeData();
    }
  });

  function onUploadProgress(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    console.log(percentCompleted + '% Done');
  }

  const loadEmployeeData = async () => {
    var employee_data = await getEmployee();

    if (employee_data) {
      if (employee_data.user) {
        if (employee_data.user.id) {
          setEmployeeID(employee_data.user.id);
        }
      }
    }
  };

  const handleSubmission = async (e, order) => {
    handleCloseActions();
    try {
      setLoadingFile(true);
      e.preventDefault();

      const form = document.querySelector('#my-form');
      const data = new FormData(form);
      
      let updateData = await directus.uploadFiles(data, onUploadProgress);

      await directus.createItem('order_files', {
        status: 'published',
        order_id: order.id,
        directus_file_id: updateData.data.id
      });

      loadOrderSelected(false);
      setLoadingFile(false);
      var message = 'File uploaded successfully';
      enqueueSnackbar(message, {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'success',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    } catch (e) {
      setLoadingFile(false);
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
  };

  const openCustomEmail = () => {
    handleCloseActions();
    setIsCustomEmail(true);

    var send_to_email = '';
    var cc_email = '';

    if (selectedOrder.supplier) {
      if (selectedOrder.supplier.email) {
        send_to_email = selectedOrder.supplier.email;
      }
    }
    if (selectedOrder.employee.email) {
      cc_email = selectedOrder.employee.email;
    } else if (selectedOrder.employee.user.email) {
      cc_email = selectedOrder.employee.user.email;
    }

    setDataCustomEmail({ send_to_email: send_to_email, cc_email: cc_email });
  };

  const handleChangeInput = (e, type) => {
    const { name, value } = e.target;
    var check_value = null;
    if (value) {
      if (value === ' ') {
        check_value = '';
      } else {
        check_value = value.split(/[ ,]+/).join(';');
        check_value = check_value.split(/[;;,]+/).join(';');
      }
    }
    setDataCustomEmail({ ...dataCustomEmail, [name]: check_value });
  };

  const sendEmail = () => {
    setCustomEmailLoading(true);
    var cc_email = dataCustomEmail.cc_email;
    var send_to_email = dataCustomEmail.send_to_email;
    var cc_email_array = cc_email.split(';');
    var send_to_email_array = send_to_email.split(';');

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    var invalid_email = [];
    var invalid_email_count = 0;
    var invalid_email_text = '';
    /* eslint-disable array-callback-return*/
    cc_email_array.map(value => {
      if (value) {
        if (!value.match(mailformat)) {
          invalid_email.push(value);
          invalid_email_count++;
        }
      }
    });
    /* eslint-disable array-callback-return*/
    send_to_email_array.map(value => {
      if (value) {
        if (!value.match(mailformat)) {
          invalid_email.push(value);
          invalid_email_count++;
        }
      }
    });
    /* eslint-disable array-callback-return*/
    invalid_email.map((value, index) => {
      invalid_email_text += value;
      if (index < invalid_email.length - 1) {
        invalid_email_text += ', ';
      }
    });

    if (invalid_email_count > 0) {
      var error_message = 'Invalid email address' + (invalid_email_count > 1 ? 'es' : '');
      error_message += '\n' + invalid_email_text + '';
      enqueueSnackbar(error_message, {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        style: { width: '300px', whiteSpace: 'pre-line' },
        autoHideDuration: 4200,
        action: CloseSnackbarAction
      });
      setCustomEmailLoading(false);
    } else {
      editSubmit(selectedOrder.id, 'sent', false, true);
    }
  };

  const handleClickOpenDialog = () => {
    handleCloseActions();
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const updateStatus = async (id, status) => {
    setLoadingUpdateStatus(true);
    handleCloseActions();
    setLoadingStatusCompleted(true);
    handleCloseDialog();

    await directus.updateItem('orders', id, {
      status: status
    });

    EventEmitter.emit('load_all_orders', { loadStatus: false });

    loadOrderSelected();
    setLoadingStatusCompleted(false);

    var message = 'Status has changed to ' + status;
    enqueueSnackbar(message, {
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      variant: 'success',
      autoHideDuration: 3200,
      action: CloseSnackbarAction
    });
    setLoadingUpdateStatus(false);
  };

  const goToFirstPage = () => {
    localStorage.setItem("limit_and_page", JSON.stringify({page: 1, limit: 100}));
    EventEmitter.emit("load_all_orders");
  }

  const duplicateData = async value => {
    handleCloseActions();
    setLoadingDuplicate(true);
    var directusUser = JSON.parse(window.localStorage.getItem('directus_employee'));
    const date = moment().format('YYYY-MM-DD HH:mm:ss');
    var order_body = {
      employee: directusUser.id,
      status: 'draft',
      order_date: date,
      notes: value.notes,
      cc_email: value.cc_email,
      supplier: value.supplier ? value.supplier.id : null,
      farm_code: value.farm_code ? value.farm_code.id : null,
      category_type: value.category_type ? value.category_type.id : null,
      category_description: value.category_description
        ? value.category_description.id
          ? value.category_description.id
          : value.category_description
        : value.category_description,
      delivery_address_city: value.delivery_address_city,
      delivery_address_line_1: value.delivery_address_line_1,
      delivery_address_line_2: value.delivery_address_line_2,
      delivery_address_name: value.delivery_address_name,
      delivery_address_option: value.delivery_address_option,
      delivery_address_postcode: value.delivery_address_postcode,
      delivery_address_state: value.delivery_address_state,
      delivery_instructions: value.delivery_instructions,
    };

    let orderResult = null;

    try {
      orderResult = await directus.createItems('orders', order_body);
    } catch (error) {
      enqueueSnackbar(ErrorMessage(error), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }

    var order_items = value.order_items;
    var order_list = [];
    
    if (order_items && order_items.length > 0) {
      order_items.map(data => {
        order_list = [
          ...order_list,
          {
            order: { id: orderResult.data.id },
            order_item: {
              description: data.order_item.description,
              quantity: data.order_item.quantity,
              unit_price: data.order_item.unit_price,
              category_type: data.order_item.category_type ? data.order_item.category_type.id : null,
              category_description: data.order_item.category_description
                ? data.order_item.category_description.id
                  ? data.order_item.category_description.id
                  : data.order_item.category_description
                : '',
              sort: data.order_item.sort,
              supplier_code: data.order_item.supplier_code
            }
          }
        ];
      });

      for (let index = 0; index < order_list.length; index++) {
        let order_item_list = order_list[index];
        let order_item = order_item_list.order_item;
        delete order_item.id;
        var orderItemResult = await directus.createItems('order_items', order_item);
        if (orderItemResult && orderItemResult.data) {
          order_item_list.order_item = { id: orderItemResult.data.id };
          order_list[index] = order_item_list;
        }
      }

      try {
        await directus.createItems('orders_items', order_list);
      } catch (error) {
        enqueueSnackbar(ErrorMessage(error), {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'error',
          autoHideDuration: 3200,
          action: CloseSnackbarAction
        });
      }
    }

    goToFirstPage();

    var message = 'Order has been duplicated';
    enqueueSnackbar(message, {
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      variant: 'success',
      autoHideDuration: 3200,
      action: CloseSnackbarAction
    });
    setLoadingDuplicate(false);
  };
  
  const resendToSuplier = async id => {
    handleCloseActions();
    setLoadingResendSupplier(true);
    try {
      let result = await directus.api.get(`/custom/orders/send_po_email/${id}`, { resend_supplier: true });
      if (result.data) {
        enqueueSnackbar('Order has been resent to supplier', {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'success',
          autoHideDuration: 3200,
          action: CloseSnackbarAction
        });
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
    setLoadingResendSupplier(false);
  };

  const exportPO = async data => {
    handleCloseActions();
    setLoadingExport(true);
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + directus.api.auth.config.token }
      };

      var react_app_url = process.env.REACT_APP_URL;
      if (window.location.hostname.search('interax') !== -1) {
        react_app_url = process.env.REACT_APP_URL_INTERAX;
      }
      await fetch(`${react_app_url}${JSON.parse(window.localStorage.getItem('company'))}/custom/export/purchase_order/` + data.id, requestOptions)
        .then(response => response.blob())
        .then(async blob => {
          var file = await blob.text();
          const url = window.URL.createObjectURL(blob);

          if (file.includes('error')) {
            var message = JSON.parse(file).error.message;
            alert(JSON.parse(message).error.message);
          } else {
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            // a.download = 'PO-Export' + '-' + moment(data.order_date).format('YYYY-MM-DD') + '.pdf';
            a.download = `P${data.order_number}-${moment(data.order_date).format('YYYY-MM-DD')}.pdf`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          }
        });
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
    setLoadingExport(false);
  };

  const handleCloseActions = () => {
    setAnchorElOrdersType(null);
  };

  const handleOpenActions = event => {
    setAnchorElOrdersType(event.currentTarget);
  };

  const handleCloseDeleteOrderDialog = () => {
    setOpenDeleteOrderDialog(false);
  };

  const handleOpenDeleteOrderDialog = () => {
    handleCloseActions();
    setOpenDeleteOrderDialog(true);
  };

  const deleteOrder = async () => {
    setLoadingDeleteDialog(true);
    try {
      var orders = await directus.getItems('orders', {
        fields: 'id,order_items.*,files.*',
        filter: {
          id: selectedOrder.id
        }
      });

      let delItems = [];
      let delItems2 = [];
      let delFiles = [];

      if (orders.data[0]) {
        var deleted_order = orders.data[0];
        if (deleted_order.order_items) {
          if (deleted_order.order_items.length > 0) {
            //eslint-disable-next-line array-callback-return
            deleted_order.order_items.map(value => {
              delItems.push(value.id);
              delItems2.push(value.order_item);
            });
          }
        }

        if (deleted_order.files) {
          if (deleted_order.files.length > 0) {
            deleted_order.files.map(value => {
              delFiles.push(value.id);
            });
          }
        }
      }

      if (delItems.length > 0) {
        await directus.deleteItems('orders_items', delItems);
        await directus.deleteItems('order_items', delItems2);
      }

      if (delFiles.length > 0) {
        await directus.deleteItems('order_files', delFiles);
      }

      await directus.deleteItem('orders', selectedOrder.id);
      enqueueSnackbar('Data deleted successfully', {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'success',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
    setLoadingDeleteDialog(false);
    handleCloseDeleteOrderDialog();

    EventEmitter.emit('load_all_orders', { value: true });
  };

  const handleSaveCompletedOrder = (orderID) => {
    editSave(orderID);
    handleCloseActions();
  }

  const handleClick = id => {
    window.open(id);
  };

  const handleDelete = async id => {
    setLoadingDeleteFile(true);
    let delItems = [];
    delItems = [...delItems, id];
    
    if (delItems.length > 0) {
      await directus.deleteItems('order_files', delItems);
    }
    
    await loadOrderSelected(false);
    setLoadingDeleteFile(false);
    var message = 'File has been deleted';
    enqueueSnackbar(message, { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
  };

  //generate invoice
  const handleOpenGenerateInvoiceDialog = value => {
    setOpenGenerateInvoiceDialog(true);
    setSelectedAttachment(value);
  };
  
  const handleCloseGenerateInvoiceDialog = () => {
    setOpenGenerateInvoiceDialog(false);
    setSelectedAttachment(null);
  };

  const generateInvoice = async (isMatchedPO = false) => {
    try {
      setLoadingGenerateInvoice(true);
      const requestOptions = {
        method: 'GET'
      };

      var react_app_url = process.env.REACT_APP_URL;
      if (window.location.hostname.search('interax') !== -1) {
        react_app_url = process.env.REACT_APP_URL_INTERAX;
      }
      await fetch(`${react_app_url}${selectedAttachmentRef.current.directus_file_id.data.asset_url}`, requestOptions)
        .then(res => res.blob()) // Gets the response and returns it as a blob
        .then(async blob => {
          var file_name = selectedAttachmentRef.current.directus_file_id.filename_download;
          const data = new FormData();
          data.append('blob', blob, `PO-${selectedOrder.order_number}-${file_name}`);

          let updateData = await directus.api.post('/custom/emails/upload-order-invoice', data, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

          if (!updateData.error_message) {
            if (isMatchedPO) {
              await directus.createItem('invoices_orders', {
                date_matched: moment().format('YYYY-MM-DD'),
                invoices_id: updateData.invoice_id,
                orders_id: selectedOrder.id
              });
            }
            if (updateData.invoice_id) {
              EventEmitter.emit('hideFilter', { value: true });
              EventEmitter.emit('reload_item', { value: `/invoice/${updateData.invoice_id}` });
              navigate(`/invoice/${updateData.invoice_id}`);
              var message = 'A new invoice has been created.';
              enqueueSnackbar(message, { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
            }
          } else {
            enqueueSnackbar(updateData.error_message, {
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              variant: 'error',
              autoHideDuration: 3200,
              action: CloseSnackbarAction
            });
            setLoadingGenerateInvoice(false);
          }
        });
    } catch (e) {
      if (e.message.includes('Error! The image')) {
        enqueueSnackbar('This PO attachment cannot be generated into invoice.', {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'error',
          autoHideDuration: 3200,
          action: CloseSnackbarAction
        });
      } else {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
      }
    }
    setLoadingGenerateInvoice(false);
  };

  const isButtonDisabled = disabledCheck || loadingDuplicate || loadingSubmit || loadingFile || loadingStatusCompleted;

  return (
    <div>
      <div style={{ width: '100%', display: 'flex', marginTop: '30px'}}>
        <div style={{ width: '50%', flexWrap: 'wrap', display: 'flex', gap: '10px' }}>
          {loadingDeleteFile ?
            <span display="inline-flex" style={{ marginTop: '5px' }}>
              <CircularProgress size={20} style={{  color: '#67AC2D' }} />
            </span>
          : selectedOrder.files && selectedOrder.files.length > 0
            ? selectedOrder.files.map((file, index) => {
              if (file.directus_file_id) {
                return (
                  <Grid key={index} span="true" style={{ display: 'flex' }}>
                    {POApproverPermission() ? (
                      <div style={{ margin: '7px 5px 0px', cursor: 'pointer' }}>
                        <CustomTooltip arrow title={<Typography variant="h6">Generate Invoice</Typography>}>
                          <ContentCopyIcon className={classes.generate_invoice_button} onClick={() => handleOpenGenerateInvoiceDialog(file)} />
                        </CustomTooltip>
                      </div>
                    ) : (
                      ''
                    )}
                    <Chip
                      icon={<InsertDriveFileIcon />}
                      label={file.directus_file_id.filename_download}
                      onClick={id => handleClick(file.directus_file_id.data.full_url)}
                      onDelete={() => handleDelete(file.id)}
                      variant="outlined"
                      style={{ 
                        marginRight: '5px',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        maxWidth: '400px'
                      }}
                    />
                  </Grid>
                );
              } else {
                return null;
              }
            })
          : ''}
        </div>
        <div style={{ width: '50%', maxWidth: '50%', display: 'flex', justifyContent: 'flex-end', gap: '10px', flexWrap: 'wrap' }}>
          {loadingSubmit || loadingExport || loadingResendSupplier || loadingDuplicate || loadingUpdateStatus || loadingFile ? (
            <span display="inline-flex" style={{ marginTop: '5px' }}>
              <CircularProgress size={20} style={{  color: '#67AC2D' }}/>
            </span>
          ) : (
            <>
              <CustomButton
                variant="contained"
                onClick={handleOpenActions}
              >
                Actions
              </CustomButton>
              
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorElOrdersType}
                keepMounted
                open={Boolean(openActions)}
                onClose={handleCloseActions}
                className={classes.listStyle}
              >
                <List>
                  {selectedOrder.status !== 'cancelled' ?
                    <MenuItem className={classes.menuitem2} onClick={() => {}} >
                      <form id="my-form" className={classes.form}>
                        <input type="file" name="file" onChange={e => handleSubmission(e, selectedOrder)} id="contained-button-file" className={classes.input} />
                        <label htmlFor="contained-button-file" className={classes.fileInputLabel}>Attach File</label>
                      </form>
                    </MenuItem>
                  : ''}
                  {selectedOrder.status === 'completed' ?
                    <MenuItem
                      className={classes.menuitem}
                      onClick={() => handleSaveCompletedOrder(selectedOrder.id)}
                      disabled={isButtonDisabled}
                    >
                      Save
                    </MenuItem>
                  : ''}
                  {selectedOrder.status === 'draft' ?
                    <>
                      {selectedSupplier ? (
                        selectedSupplier.id ? (
                          selectedSupplier.email ? (
                            <MenuItem
                              disabled={isButtonDisabled || !delivery_address_check}
                              className={classes.menuitem}
                              onClick={() => {
                                handleCloseActions();
                                editSubmit(selectedOrder.id, 'sent', true);
                              }}
                            >
                              Submit and Send
                            </MenuItem>
                          ) : null
                        ) : selectedOrder.supplier ? (
                          selectedOrder.supplier.email ? (
                            <MenuItem
                              disabled={isButtonDisabled || !delivery_address_check}
                              className={classes.menuitem}
                              onClick={() => {
                                handleCloseActions();
                                editSubmit(selectedOrder.id, 'sent', true);
                              }}
                            >
                              Submit and Send
                            </MenuItem>
                          ) : null
                        ) : null
                      ) : null}

                      {selectedSupplier ? (
                        selectedSupplier.id ? (
                          selectedSupplier.email ? (
                            <MenuItem
                              disabled={isButtonDisabled || !delivery_address_check}
                              className={classes.menuitem}
                              onClick={() => openCustomEmail()}
                            >
                              Submit and Custom Send
                            </MenuItem>
                          ) : null
                        ) : selectedOrder.supplier ? (
                          selectedOrder.supplier.email ? (
                            <MenuItem
                              disabled={isButtonDisabled || !delivery_address_check}
                              className={classes.menuitem}
                              onClick={() => openCustomEmail()}
                            >
                              Submit and Custom Send
                            </MenuItem>
                          ) : null
                        ) : null
                      ) : null}
                    </>
                  : ''}
                  {selectedOrder.status === 'sent' || selectedOrder.status === 'completed' ?
                    <MenuItem
                      className={classes.menuitem}
                      onClick={e => exportPO(selectedOrder)}
                      disabled={loadingFile || loadingDuplicate}
                    >
                      Export PO
                    </MenuItem>
                    : ''}
                    
                  {selectedOrder.status !== 'completed' && selectedOrder.status !== 'awaiting_Approval' ?
                    selectedOrder.status === 'awaiting_approval' ?
                      AdminPermission() ?
                        <MenuItem
                          className={classes.menuitem}
                          onClick={handleClickOpenDialog}
                        >
                          Update Status
                        </MenuItem>
                      : ''
                    :
                      <MenuItem
                        className={classes.menuitem}
                        onClick={handleClickOpenDialog}
                      >
                        Update Status
                      </MenuItem>
                  : ''}
                  
                  <MenuItem
                    className={classes.menuitem}
                    onClick={e => duplicateData(selectedOrder)}
                  >
                    Duplicate
                  </MenuItem>
                  {selectedOrder.status === 'draft' ?
                    POApproverPermission() ?
                      <MenuItem
                        className={classes.menuitem}
                        onClick={() => handleOpenDeleteOrderDialog(selectedOrder.id)}
                      >
                        Delete Order
                      </MenuItem>
                    : selectedOrder.owner ?
                      selectedOrder.owner.id === employeeIDRef.current ?
                        <MenuItem
                          className={classes.menuitem}
                          onClick={() => handleOpenDeleteOrderDialog(selectedOrder.id)}
                          disabled={isButtonDisabled}
                        >
                          Delete Order
                        </MenuItem>
                      : ''
                    : ''
                  : ''}
                </List>
              </StyledMenu>

              {selectedOrder.status === 'sent' || selectedOrder.status === 'completed' ?
                <>
                  <CustomButton2
                    variant="contained"
                    onClick={() => resendToSuplier(selectedOrder.id)}
                    disabled={loadingFile || loadingDuplicate}
                    startIcon={<img src='/static/images/icons/resend.svg' />}
                  >
                    Resend
                  </CustomButton2>
                </>
              : ''}

              {selectedOrder.status === 'draft' ? (
                <>
                  <CustomButton
                    variant="contained"
                    onClick={() => editSubmit(selectedOrder.id, 'draft')}
                  >
                    Save Draft
                  </CustomButton>

                  <CustomButton2
                    variant="contained"
                    onClick={() => editSubmit(selectedOrder.id, 'sent')}
                    disabled={isButtonDisabled || !delivery_address_check}
                  >
                    Submit
                  </CustomButton2>
                </>
              ) : ''}
            </>
          )}
        </div>
      </div>

      <Dialog isopen={`${openDeleteOrderDialog}`} open={openDeleteOrderDialog} fullWidth={true} onClose={handleCloseDeleteOrderDialog}>
        <DialogTitle disableTypography={true} id="form-dialog-title">
          <Typography component="div" variant="h6">
            <h2>Delete Order</h2>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6">Are you sure?</Typography>
        </DialogContent>
        <DialogActions style={{ height: '60px' }}>
          {loadingDeleteDialog ? (
            <CircularProgress className={classes.circular} style={{  color: '#67AC2D' }}/>
          ) : (
            <div>
              <CancelButton id="cancel_btn" size="small" variant="contained" onClick={handleCloseDeleteOrderDialog}>
                Cancel
              </CancelButton>
              <SubmitButton style={{ marginLeft: '10px' }} id="save_btn" size="small" variant="contained" onClick={() => deleteOrder()}>
                Delete
              </SubmitButton>
            </div>
          )}
        </DialogActions>
      </Dialog>

      <Dialog open={isCustomEmail} onClose={closeCustomEmail}>
        <DialogTitle disableTypography={true} id="form-dialog-title">
          <Typography component="div" variant="h6">
            <h2>Submit and Custom Send</h2>
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: '450px' }}>
          <TextField
            id="email_text"
            label="Email"
            variant="outlined"
            fullWidth={true}
            name="send_to_email"
            value={dataCustomEmail.send_to_email}
            placeholder={'user1@gmail.com;user2@gmail.com'}
            onChange={handleChangeInput}
          />
          <br />
          <br />
          <TextField
            id="cc_email_text"
            label="CC Email"
            variant="outlined"
            fullWidth={true}
            name="cc_email"
            value={dataCustomEmail.cc_email}
            placeholder={'user1@gmail.com;user2@gmail.com'}
            onChange={handleChangeInput}
          />
        </DialogContent>

        <DialogActions style={{ height: '60px', marginRight: '5px' }}>
          {customEmailLoading ? (
            <CircularProgress className={classes.circular} style={{  color: '#67AC2D' }}/>
          ) : (
            <div>
              <CancelButton id="cancel_btn" size="small" variant="contained" onClick={closeCustomEmail}>
                Cancel
              </CancelButton>
              <SubmitButton
                id="save_btn"
                size="small"
                variant="contained"
                onClick={() => sendEmail()}
                disabled={!dataCustomEmail.send_to_email}
              >
                Save
              </SubmitButton>
            </div>
          )}
        </DialogActions>
      </Dialog>

      <Dialog open={open} className={classes.DialogStatusStyle}>
        <IconButton aria-label="close" className={classes.dialogCloseBtn} onClick={handleCloseDialog}>
          <ClearIcon />
        </IconButton>
        <DialogTitle>Update {selectedOrder ? selectedOrder.order_number : ''} Status</DialogTitle>
        <DialogActions>
          {AdminPermission() && selectedOrder.status !== 'draft' ?
            <DraftButton variant="contained" onClick={() => updateStatus(selectedOrder.id, 'draft')}>
              <Typography variant="h6">Draft</Typography>
            </DraftButton>
          : ''}

          {selectedOrder.status !== 'sent' ?
            <SentButton disabled={isButtonDisabled || !delivery_address_check} variant="contained" onClick={() => updateStatus(selectedOrder.id, 'sent')}>
              <Typography variant="h6">Sent</Typography>
            </SentButton>
          : ''}

          {POApproverPermission() ? (
            <CompletedButton disabled={isButtonDisabled || !delivery_address_check} variant="contained" onClick={() => updateStatus(selectedOrder.id, 'completed')}>
              <Typography variant="h6">Completed</Typography>
            </CompletedButton>
          ) : POCreatorPermission() && selectedOrder.invoices ? (
            selectedOrder.invoices.length === 0 ? (
              <CompletedButton disabled={isButtonDisabled || !delivery_address_check} variant="contained" onClick={() => updateStatus(selectedOrder.id, 'completed')}>
                <Typography variant="h6">Completed</Typography>
              </CompletedButton>
            ) : (
              ''
            )
          ) : (
            ''
          )}

          {selectedOrder.invoices ?
            (selectedOrder.invoices.length === 0 ? 
              selectedOrder.status !== 'cancelled' ? (
                <CancelledButton variant="contained" onClick={() => updateStatus(selectedOrder.id, 'cancelled')}>
                  <Typography variant="h6">Cancelled</Typography>
                </CancelledButton>
              )
              : ''
            : '')
          : ''}
        </DialogActions>
      </Dialog>

      <Dialog isopen={`${openGenerateInvoiceDialog}`} open={openGenerateInvoiceDialog} fullWidth={true} onClose={handleCloseGenerateInvoiceDialog}>
        <DialogTitle disableTypography={true} id="form-dialog-title">
          <Typography component="div" variant="h6">
            <h2>Generate Invoice</h2>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6">Are you sure you want to generate a new invoice record from this attachment?</Typography>
        </DialogContent>
        <DialogActions style={{ height: '60px' }}>
          {loadingGenerateInvoice ? (
            <CircularProgress className={classes.circular} style={{ marginRight: '10px', color: '#67AC2D' }}/>
          ) : (
            <div>
              <CancelButton id="cancel_btn" size="small" variant="contained" onClick={handleCloseGenerateInvoiceDialog}>
                Cancel
              </CancelButton>
              <DraftButton
                style={{ marginLeft: '10px', width: '125px' }}
                id="save_btn"
                size="small"
                variant="contained"
                onClick={() => generateInvoice()}
              >
                Generate
              </DraftButton>
              <CompletedButton
                style={{ marginLeft: '10px', width: '225px' }}
                id="save_btn"
                size="small"
                variant="contained"
                onClick={() => generateInvoice(true)}
              >
                Generate and Match PO
              </CompletedButton>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ButtonsView