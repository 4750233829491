import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: "10px"
  },
  
  buttonLeft: {
    backgroundColor: 'white',
    padding: 0,
    width: '60px',
    borderRadius: '24px 0 0 24px',
    borderRight: 0,
    boxShadow: '0 0 black',
    height: 30,
    border: '0 !important',
  },
  buttonRight: {
    backgroundColor: 'white',
    padding: 0,
    width: '60px',
    borderRadius: '0 24px 24px 0',
    boxShadow: '0 0 black',
    height: 30,  
    border: '0 !important',  
  },
  tablehead:{
    borderRadius:'8px',
    height:'38px',
    padding: '8px 0px 6px 0px',
    marginBottom:'10px',
    position: 'sticky',
    top: '12px',
  },
  circular: {
    color: '#5bfa17',
    marginLeft: '45%'
  },
  headerSort: {
    display: "flex",
    overflow: "hidden",
    cursor: "pointer"
  },
  ascSort: {
    color: "#516573", 
    fontSize: "20px", 
    position: "absolute", 
    marginTop: "1px", 
    marginLeft: "3px",
    transform: "rotateX(180deg)"
  },
  descSort: {
    color: "#516573", 
    fontSize: "20px", 
    position: "absolute", 
    marginTop: "1px", 
    marginLeft: "3px"
  }
}));

export const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));