import React, { useState, useEffect } from 'react';
import {
  withStyles,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  TextField,
  // Chip,
  Typography,
  Grid,
  Switch
} from '@material-ui/core';
import directus from '../../services/directus';
import { SubmitButton } from 'src/components/ReusableStyle';
import { useStylesEmployees } from './style.js';
import useStateRef from "react-usestateref";
import { useSnackbar } from 'notistack';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ErrorMessage from 'src/views/Components/ErrorMessage';
import Autocomplete from '@material-ui/lab/Autocomplete';

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderColor: 'red',
      backgroundColor: 'white',
      borderRadius: '6px',
      marginBottom: '6px',
      marginRight: '6px',
      height: '30px'
    },
  },
})(TextField);

const CssTextField2 = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderColor: 'red',
      backgroundColor: 'white',
      borderRadius: '6px',
      height: '35px'
    },
    // '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl': {
    //   marginTop: '8px'
    // },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: "translate(15px, -12px) scale(0.75)"
    }
  },
})(TextField);

const Configuration = (props) => {
  /* eslint-disable no-unused-vars*/
  const classes = useStylesEmployees();
  const [initialized, setInitialized] = useState(false);

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [dataConfig, setDataConfig, dataConfigRef] = useStateRef(null);
  const [passwordOutgoing, setPasswordOutgoing] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const [selectedEncryption, setSelectedEncryption] = React.useState('');
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadConfiguration();
    }
  });
  /* eslint-enable react-hooks/exhaustive-deps*/

  // let disable = dataSupplierRef.current.code === '' || dataSupplierRef.current.name === '';

  const loadConfiguration = async (data) => {
    try {
      let filterData;

      var result = await directus.getItems('configuration', filterData);
      if (result.data[0]) {
        var encryption = result.data[0].outgoing_mail_encryption;
        if (encryption) {
          if (encryption === "ssl") {
            setSelectedEncryption({ name: "SSL", value: "ssl" })
          } else {
            setSelectedEncryption({ name: "TLS", value: "tls" })
          }
        }
      }
      setDataConfig(result.data[0])
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  };


  const handleChangeInput = (e, type) => {
    if (type === "encryption") {
      setSelectedEncryption(e);
    } else {
      const { name, value } = e.target;
      setDataConfig({ ...dataConfigRef.current, [name]: value })
    }
  }

  const handleChangePassword = (e) => {
    setPasswordOutgoing(e.target.value);
  }

  const switchChange = (e) => {
    const name = e.target.name;
    const value = e.target.checked;

    setDataConfig({ ...dataConfigRef.current, [name]: value })
  }

  const handleUpdate = async () => {
    setLoadingUpdate(true);
    try {
      var data = dataConfigRef.current;
      const body = {
        id: data.id,
        admin_notification_emails: data.admin_notification_emails,
        enable_test_email: data.enable_test_email === true ? 1 : 0,
        test_email: data.test_email,
        tenant_id: data.tenant_id,
        client_id: data.client_id,
        client_secret: data.client_secret,
        po_mail_server: data.po_mail_server,
        outgoing_mail_username: data.outgoing_mail_username,
        outgoing_mail_password: passwordOutgoing ? passwordOutgoing : data.outgoing_mail_password,
        outgoing_mail_server: data.outgoing_mail_server,
        outgoing_mail_port: data.outgoing_mail_port,
        outgoing_mail_encryption: selectedEncryption ? selectedEncryption.value : '',
        email_subject_for_invoice_matched: data.email_subject_for_invoice_matched,
        email_subject_for_invoice_not_matched: data.email_subject_for_invoice_not_matched,
      }

      await directus.updateItem('configuration', body.id, body);
      setPasswordOutgoing(null);
      enqueueSnackbar("Data updated successfully", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200 });
      loadConfiguration();
      setLoadingUpdate(false);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  }

  return (
    <div >
      {dataConfigRef.current ? dataConfigRef.current.id ?
        <div >
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{ backgroundColor: 'white', paddingLeft: "20px" }}>
            <ListItem style={{ marginTop: "10px" }}>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h4">Admin Settings</Typography></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Notification Emails</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField disabled={!props.config_settings} style={{ width: "100%" }} aria-label='Admin Notification Emails' name="admin_notification_emails" onChange={handleChangeInput} value={dataConfigRef.current.admin_notification_emails ? dataConfigRef.current.admin_notification_emails : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>

            <ListItem style={{ marginTop: "20px" }}>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h4">Tests Settings</Typography></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}>
                      <Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "38px" }}>Enable Test Email</Typography>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                      <FormControlLabel
                        control={
                          <Switch
                            disabled={!props.config_settings}
                            name="enable_test_email"
                            defaultChecked={dataConfigRef.current.enable_test_email === true ? true : false}
                            onChange={switchChange}
                          />
                        }
                        label="Test Email Enabled"
                      />

                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Test Email Address</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField disabled={!props.config_settings} style={{ width: "100%" }} aria-label='Test Email Address' name="test_email" onChange={handleChangeInput} value={dataConfigRef.current.test_email ? dataConfigRef.current.test_email : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            {dataConfigRef.current && dataConfigRef.current.show_invoices ?
              <>
                <ListItem style={{ marginTop: "20px" }}>
                  <ListItemText
                    primary={
                      <Grid container>
                        <Grid item xs={3}><Typography variant="h4">Invoice Email Inbox</Typography></Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container>
                        <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Tenant ID</Typography></Grid>
                        <Grid item xs={6} lg={3}><CssTextField disabled={!props.config_settings} style={{ width: "100%" }} aria-label='Tenant ID' name="tenant_id" onChange={handleChangeInput} value={dataConfigRef.current.tenant_id ? dataConfigRef.current.tenant_id : ''} variant="outlined" /></Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container>
                        <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Client ID</Typography></Grid>
                        <Grid item xs={6} lg={3}><CssTextField disabled={!props.config_settings} style={{ width: "100%" }} aria-label='Client ID' name="client_id" onChange={handleChangeInput} value={dataConfigRef.current.client_id ? dataConfigRef.current.client_id : ''} variant="outlined" /></Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container>
                        <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Client Secret</Typography></Grid>
                        <Grid item xs={6} lg={3}><CssTextField disabled={!props.config_settings} style={{ width: "100%" }} aria-label='Client Secret' name="client_secret" onChange={handleChangeInput} value={dataConfigRef.current.client_secret ? dataConfigRef.current.client_secret : ''} variant="outlined" /></Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container>
                        <Grid item xs={3}> <Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Mail Server</Typography></Grid>
                        <Grid item xs={6} lg={3}><CssTextField disabled={!props.config_settings} style={{ width: "100%" }} aria-label='Invoice Mail Server' name="po_mail_server" onChange={handleChangeInput} value={dataConfigRef.current.po_mail_server ? dataConfigRef.current.po_mail_server : ''} variant="outlined" /></Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={6} lg={3}>
                          <SubmitButton
                            id="save_btn"
                            size="small"
                            variant="contained"
                            onClick={() => window.open('https://admin.digiorder.com.au/mfm/custom/oauth2/callback')}
                            disabled={!props.config_settings}
                          >
                            Authorise
                          </SubmitButton>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
              </>
            : ''}
            <ListItem style={{ marginTop: "20px" }}>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h4">Outgoing Emails</Typography></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Username</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField disabled={!props.config_settings} style={{ width: "100%" }} aria-label='Username' name="outgoing_mail_username" onChange={handleChangeInput} value={dataConfigRef.current.outgoing_mail_username ? dataConfigRef.current.outgoing_mail_username : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Password</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField disabled={!props.config_settings} aria-label='Password' type="password" style={{ width: "100%" }} name="outgoing_mail_password" onChange={handleChangePassword} value={passwordOutgoing ? passwordOutgoing : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}> <Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Mail Server</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField disabled={!props.config_settings} style={{ width: "100%" }} aria-label='Outgoing Mail Server' name="outgoing_mail_server" onChange={handleChangeInput} value={dataConfigRef.current.outgoing_mail_server ? dataConfigRef.current.outgoing_mail_server : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}> <Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Mail Port</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField disabled={!props.config_settings} style={{ width: "100%" }} aria-label='Mail Port' name="outgoing_mail_port" onChange={handleChangeInput} value={dataConfigRef.current.outgoing_mail_port ? dataConfigRef.current.outgoing_mail_port : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}> <Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Mail Encryption</Typography></Grid>
                    <Grid item xs={6} lg={3}>
                      <Autocomplete
                        id="size-small-standard"
                        size="small"
                        options={[{ name: "SSL", value: "ssl" }, { name: "TLS", value: "tls" }]}
                        getOptionLabel={(option) => option.name}
                        value={selectedEncryption ? selectedEncryption : null}
                        onChange={(event, newValue) => {
                          handleChangeInput(newValue, "encryption");
                        }}
                        renderInput={(params) =>
                          <CssTextField2
                            {...params}
                            variant="outlined"
                            name="encryption"
                          />}
                      />
                    </Grid>
                  </Grid>
                }
              />

            </ListItem>
            <ListItem style={{ marginTop: "20px" }}>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={6} lg={3}><Typography variant="h4">Email Subject for Invoice</Typography></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem style={{ marginTop: "-10px", marginBottom: "5px" }}>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={12}><Typography variant="h6">Template tags: {"{{ OrderNumber}}, {{InvoiceNumber}}, {{CompanyName}}, {{CustomerName}}, {{SupplierName}}"}</Typography></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Email Subject for Invoice Matched</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField disabled={!props.config_settings} style={{ width: "100%" }} aria-label='Email Subject for Invoice Matched' name="email_subject_for_invoice_matched" onChange={handleChangeInput} value={dataConfigRef.current.email_subject_for_invoice_matched ? dataConfigRef.current.email_subject_for_invoice_matched : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Email Subject for Invoice Not Matched</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField disabled={!props.config_settings} style={{ width: "100%" }} aria-label='Email Subject for Invoice Not Matched' name="email_subject_for_invoice_not_matched" onChange={handleChangeInput} value={dataConfigRef.current.email_subject_for_invoice_not_matched ? dataConfigRef.current.email_subject_for_invoice_not_matched : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem style={{ marginBottom: "20px", height: "60px" }}>
              {!loadingUpdate ?
                <SubmitButton
                  id="save_btn"
                  size="small"
                  variant="contained"
                  onClick={() => handleUpdate()}
                  disabled={!props.config_settings}
                >
                  Update
                </SubmitButton>
                : <CircularProgress className={classes.circular} style={{ marginLeft: "0%" }} />}
            </ListItem>
          </List>
        </div>
        : <Typography variant="h5" style={{ fontWeight: "normal", textAlign: "center", marginTop: "20px" }}>No Configuration</Typography> :
        <CircularProgress className={classes.circular} />
      }
    </div>
  );
}

export default Configuration;
