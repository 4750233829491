import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Container, TextField, Typography, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import ResetPassword from './ResetPassword';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import useStateRef from "react-usestateref";

import LoginPassword from './LoginPassword';
import LoginSMSEmail from './LoginSMSEmail';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const [isInitialized, setIsInitialized] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [mode, setMode, modeRef] = useStateRef(1);


  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true);
    }
  }, [isInitialized, isBusy]);

  const sendOTP = (company, email, modeValue) => {
    setIsBusy(true);

    if (modeValue !== 4) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: email, channel: modeValue === 2 ? 'sms' : 'email' })
      };

      var react_app_url = process.env.REACT_APP_URL;
      if (window.location.hostname.search('interax') !== -1) {
        react_app_url = process.env.REACT_APP_URL_INTERAX;
      }

      fetch(`${react_app_url}console/custom/auth/otp`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.error) {
            window.localStorage.clear();
            window.alert(result.error.message);
            setIsBusy(false);
          }
          else {
            setMode(modeValue)
            setIsBusy(false);
          }
        })
    }
    else {
      setMode(modeValue);
      setIsBusy(false);
    }
  }

  return (
    <Page className={classes.root} title="Login">
      <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              company: 'mfm',
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              company: Yup.string()
                .email('Company is required')
                .max(255),
              email: Yup.string()
                .email('Must be a valid Value')
                .max(255),
            })}
            onSubmit={form => {
              setIsBusy(true);
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
              <div>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    Sign in
                  </Typography>
                </Box>

                {/* <TextField
                  disabled={modeRef.current=== 2||modeRef.current=== 3}
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Company Domain"
                  margin="normal"
                  name="company"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="company"
                  value={values.company}
                  variant="outlined"
                /> */}
                <TextField
                  disabled={modeRef.current === 2 || modeRef.current === 3}
                  fullWidth
                  label="Username"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  variant="outlined"
                />
                <Box my={2}>
                  {modeRef.current === 1 ?
                    <div>
                      <Button color="secondary" disabled={isBusy} fullWidth size="large" type="submit" variant="contained" startIcon={<PhoneIcon />} onClick={() => sendOTP(values.company, values.email, 2)}>
                        <Typography variant="h6">Send PIN to SMS</Typography>
                      </Button>
                      <br /><br />
                      <Button color="secondary" disabled={isBusy} fullWidth size="large" type="submit" variant="contained" startIcon={<EmailIcon />} onClick={() => sendOTP(values.company, values.email, 3)}>
                        <Typography variant="h6">Send PIN to Email</Typography>
                      </Button>
                      <br /><br />
                      <Button id="signin_button" color="secondary" disabled={isBusy} fullWidth size="large" type="submit" variant="contained" startIcon={<LockIcon />} onClick={() => sendOTP(values.company, values.email, 4)}>
                        <Typography variant="h6">Sign in using Password</Typography>
                      </Button>
                    </div>
                    :
                    modeRef.current === 2 || modeRef.current === 3 ?
                      <LoginSMSEmail
                        setMode={setMode}
                        company={values.company}
                        email={values.email}
                        mode={mode === 2 ? 'sms' : 'email'}
                      />
                      :
                      <LoginPassword
                        setMode={setMode}
                        company={values.company}
                        email={values.email}
                      />
                  }
                </Box>
                <ResetPassword />
              </div>

            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
