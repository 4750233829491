import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  FormControlLabel,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import './style.css';
import { CancelButton, SubmitButton } from 'src/components/ReusableStyle';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import EventEmitter from 'src/utils/EventEmitter';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import useStateRef from "react-usestateref";
import linq from 'linq';
import { useSnackbar } from 'notistack';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import ErrorMessage from 'src/components/ErrorMessage';
import directus from 'src/services/directus';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { InvoiceAdminPermission } from 'src/utils/Permission';
import { OverflowTip } from 'src/components/OverflowTip';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  daterangepickerControlSection: {
    maxWidth: '246px',
    margin: '30px auto',
    paddingTop: '50px'
  },
  orderslist: {
    borderRadius: '8px',
    height: '48px',
    padding: '6px 6px 6px 0px',
    marginBottom: '10px',
    minHeight: '48px',
  },
  statusarea: {
    height: '36px',
    marginTop: '-6px',
    paddingTop: '6px',
    paddingLeft: '10px',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    textTransform: 'capitalize'
  },

  orderstatusarea: {
    width: 'calc(100% - 10px)',
    height: '36px',
    marginTop: '-6px',
    paddingTop: '6px',
    paddingRight: '3px',
    paddingLeft: '3px',
    borderRadius: '8px',
    textTransform: 'capitalize'
  },

  amountarea: {
    width: '80%',
    height: '36px',
    marginTop: '-6px',
    paddingTop: '6px',
    paddingRight: '3px',
    paddingLeft: '3px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
  },

  amountdetail: {
    height: '30px',
    marginTop: '-3px',
    paddingRight: '15px',
    paddingTop: '4px',
    textAlign: 'right',
    zIndex: 'auto',

  },
  iconbuttonarea: {
    borderRadius: '10px',
    width: '36px',
    height: '36px',
    marginLeft: '6px',
    marginTop: '-6px',
  },
  arrowiconbutton: {
    width: "30px",
    height: "30px",
    marginTop: "-3px",
    marginLeft: "6px",
    borderRadius: "10px"
  },
  orderListContainer: {
    width: "94%",
    marginTop: "5px",
    padding: "5px 10px",
    borderRadius: "10px",
  },
  orderHeaderContainer: {
    width: "94%",
    marginTop: "15px",
    padding: "5px 10px",
    background: "#8aa9ca",
    borderRadius: "10px",
    color: "white"
  },
  itemcolumn: {
    height: '100%',
    borderRadius: '8px',
    marginRight: '6px',
    backgroundColor: 'white',
    padding: '4.5px 10px',
    '& .MuiTypography-body1': {
      overflowWrap: 'anywhere'
    }
  },
}));

function Row({ items, fallback, setOpenApproved, selectedApprovedInvoice, setSelectedApprovedInvoice, showCheckbox, setShowCheckbox, loadInvoices, page, pageNumber, saveFilterData, saveSortData, totalPage, props }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenInvoice, setIsOpenInvoice, isOpenInvoiceRef] = useStateRef(false);

  useEffect(() => {
    const onApprovedInvoice = (eventData) => {
      if (selectedApprovedInvoice.length === 0) {
        enqueueSnackbar("No Invoice Selected", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
      } else {
        setOpenApproved(true);
      }
    }
    const listener = EventEmitter.addListener('approved_invoice', onApprovedInvoice);
    return () => {
      listener.remove();
    }
  });

  const onSelectedApprovedInvoice = (e, data) => {
    var selected_invoice = selectedApprovedInvoice;
    if (selected_invoice.length === 0) {
      setSelectedApprovedInvoice([{ id: data.id, order: data.order ? data.order.id : null, invoice_status: "approved", status: "completed" }])
    } else {
      var data_exist = linq.from(selected_invoice)
        .where(w => w.id === data.id)
        .toArray();

      if (data_exist.length > 0) {
        var data_not_exist = linq.from(selected_invoice)
          .where(w => w.id !== data.id)
          .toArray();
        setSelectedApprovedInvoice(data_not_exist);
      } else {
        setSelectedApprovedInvoice([...selectedApprovedInvoice, { id: data.id, order: data.order ? data.order.id : null, invoice_status: "approved", status: "completed" }])
      }
    }
  }

  if (!items || items.length === 0) {
    return fallback;
  } else {
    return items.map((data, index) => {
      let status;
      let totalOrder = 0;
      totalOrder = data.total_amount;

      if (data.invoice_status === 'unmatched') {
        status = { backgroundColor: '#FFF0DD' };
      }
      else if (data.invoice_status === 'disputed') {
        status = { backgroundColor: '#FFDBD9' };
      }
      else if (data.invoice_status === 'matched' || data.invoice_status === 'awaiting_approval_level_2') {
        status = { backgroundColor: '#D4E3F2' };
      }
      else if (data.invoice_status === 'approved') {
        status = { backgroundColor: '#D0F2D4' };
      }
      else {
        status = { backgroundColor: '#FFFFFF' };
      }


      const formatNumber = (num) => {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }

      const onClickInvoice = () => {
        EventEmitter.emit('hideFilter', { value: true })
        EventEmitter.emit('reload_item', { value: `/invoice/${data.id}` })
        navigate(`/invoice/${data.id}`, {
          state: {
            id: index, 
            position: (pageNumber * (page - 1)) + index,
            prevLocation: window.location.pathname,
            saveFilterData: saveFilterData,
            saveSortData: saveSortData,
            totalPage: totalPage,
            pageNumber: pageNumber,
            invoiceList: items
          }
        })
      }

      const getInvoiceStatusName = () => {
        var invoice_status_name = "";
        if (data.invoice_status === "matched") {
          invoice_status_name = "Awaiting Approval"
        } else if (data.invoice_status === "awaiting_approval_level_2") {
          invoice_status_name = "Awaiting Approval Level 2"
        } else if (data.invoice_status === "approved") {
          invoice_status_name = "Complete"
        } else if (data.invoice_status === "unmatched") {
          invoice_status_name = "New"
        } else {
          invoice_status_name = data.invoice_status;
        }
        return invoice_status_name;
      }


      var matched_with_po = data.matched_po;
      var orders_data = [];

      if (data.orders) {
        if (data.orders.length > 0) {
          totalOrder = 0;
        }

        var new_orders = [];

        data.orders.map((value) => {
          if (value.orders_id) {
            new_orders.push(value);
          }
        })

        orders_data = linq.from(new_orders)
          .orderBy(o => o.orders_id.order_number)
          .toArray();
      }

      var show_checkbox = false;

      if (data.invoice_status === "matched" || data.invoice_status === "awaiting_approval_level_2") {
        var select_invoice = linq.from(selectedApprovedInvoice)
          .where(w => w.id === data.id)
          .toArray();

        if (select_invoice.length > 0) {
          show_checkbox = true;
        }
      }

      const openInvoiceDetail = (value) => {
        setIsOpenInvoice({ [index]: !value })
      }

      const getBackgroundColor = (status) => {
        var backgroundColor = "#FFFFFF";
        if (status === 'draft') {
          backgroundColor = '#D4E3F2';
        }
        else if (status === 'sent') {
          backgroundColor = '#FFF0DD';
        }
        else if (status === 'cancelled') {
          backgroundColor = '#FFDBD9';
        }
        else if (status === 'completed') {
          backgroundColor = '#DAF0DC';
        }

        return backgroundColor;
      }

      return (
        <div key={index} style={{ display: "flex" }}>
          {showCheckbox ?
            <div style={{ marginTop: "4px", minWidth: "45px", maxWidth: "45px" }}>
              {data.invoice_status === "approved" ?
                <Checkbox
                  disabled
                  icon={<CheckBoxRoundedIcon style={{ color: "#b9b9bb" }} />}
                />
                : data.invoice_status === "matched" || data.invoice_status === "awaiting_approval_level_2" ?
                  <FormControlLabel
                    style={{ marginLeft: "0px" }}
                    control={
                      <Checkbox
                        // defaultChecked={show_checkbox}
                        checked={show_checkbox}
                        icon={<CheckBoxOutlineBlankRoundedIcon style={{ color: "#8ace50" }} />}
                        checkedIcon={<CheckBoxRoundedIcon style={{ color: "#82cb43" }} />}
                        onChange={(e) => onSelectedApprovedInvoice(e, data)}
                      />
                    }
                  />
                  :
                  <Checkbox
                    disabled
                    icon={<IndeterminateCheckBoxIcon style={{ color: "#b9b9bb" }} />}
                  />
              }
            </div>
            : ''}
          <div key={index} style={{ width: "100%", cursor: "pointer" }} >
            <Paper
              elevation={0}
              className={classes.orderslist}
              style={{ height: matched_with_po ? "auto" : "48px" }}
            >
              <Grid
                container
                spacing={0}
                justifyContent="space-around"
                style={{ paddingTop: 'inherit' }}
              >
                <Grid style={{ width: "3%" }} onClick={() => openInvoiceDetail(isOpenInvoiceRef.current[index])}>
                  {matched_with_po ? !isOpenInvoiceRef.current[index] ?
                    <IconButton id={`order_edit_btn_${index}`} size="medium" className={classes.arrowiconbutton}>
                      <ArrowForwardIosIcon fontSize="small" />
                    </IconButton>
                    :
                    <IconButton id={`order_close_edit_btn`} size="medium" className={classes.arrowiconbutton}>
                      <KeyboardArrowDownIcon fontSize="large" />
                    </IconButton>
                    : ""
                  }
                </Grid>
                <Grid style={{ width: "8%" }} onClick={() => onClickInvoice()}>
                  <Typography style={{ fontWeight: 'bold', overflowX: "hidden", textOverflow: "ellipsis", paddingTop: "3px" }} component="div">
                    <OverflowTip>{moment(data.date).format("DD/MM/YYYY")}</OverflowTip>
                  </Typography>
                </Grid>
                <Grid style={{ width: "11%" }} onClick={() => onClickInvoice()}>
                  <Typography noWrap style={{ paddingRight: '10px', paddingTop: "3px" }} component="div">
                    <OverflowTip>{data.supplier_name ? data.supplier_name : ''}</OverflowTip>
                  </Typography>
                </Grid>
                <Grid style={{ width: "11%" }} onClick={() => onClickInvoice()}>
                  <Typography noWrap style={{ paddingRight: '10px', paddingTop: "3px" }} component="div">
                    <OverflowTip>{data.customer_name ? data.customer_name : ''}</OverflowTip>
                  </Typography>
                </Grid>
                <Grid style={{ width: "10%" }} onClick={() => onClickInvoice()}>
                  <Typography noWrap style={{ paddingRight: '20px', paddingTop: "3px" }} component="div">
                    <OverflowTip>{data.raw_po_number ? data.raw_po_number : ''}</OverflowTip>
                  </Typography>
                </Grid>
                <Grid style={{ width: "10%" }} onClick={() => onClickInvoice()}>
                  <Typography noWrap style={{ paddingRight: '20px', paddingTop: "3px" }} component="div">
                    <OverflowTip>{data.isDuplicate ? <b style={{ color: "red" }}>{data.number ? data.number : ''}</b> : data.number ? data.number : ''}</OverflowTip>
                  </Typography>
                </Grid>
                <Grid style={{ width: "9%" }} onClick={() => onClickInvoice()}>
                  <Typography noWrap style={{ paddingRight: '20px', paddingTop: "3px" }} component="div">
                    <OverflowTip>{data.raw_due_date ? moment(data.raw_due_date).format("DD/MM/YYYY") : ""}</OverflowTip>
                  </Typography>
                </Grid>

                <Grid style={{ width: "12%" }} onClick={() => onClickInvoice()}>
                  <Typography noWrap style={{ paddingRight: '20px', paddingTop: "3px", fontWeight: "600" }} component="div">
                    <OverflowTip>{matched_with_po ? matched_with_po : ''}</OverflowTip>
                  </Typography>
                </Grid>
                <Grid style={{ width: "12%" }} onClick={() => onClickInvoice()}>
                  <Paper elevation={0} style={status} className={classes.statusarea}>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "75%" }}>
                        <Typography noWrap style={{ paddingTop: "3px" }} component="div"><OverflowTip>{getInvoiceStatusName()}</OverflowTip></Typography>
                      </div>
                      <div style={{ width: "25%" }}>
                        {data.sent_to_accounting ?
                          <CheckCircleRoundedIcon style={{ color: "#13E000", fontSize: "20px", cursor: "pointer", verticalAlign: "middle" }} />
                          : null}
                      </div>
                    </div>
                  </Paper>
                </Grid>

                <Grid item style={{ width: "14%", display: 'inline-flex' }} onClick={() => onClickInvoice()}>
                  <Paper elevation={0} style={status} className={classes.amountarea} >
                    <Paper elevation={0} className={classes.amountdetail}>
                      <OverflowTip><Typography>{isNaN(data.total_order_amount) ? "$0.00" : "$" + formatNumber(data.total_order_amount.toFixed(2))} </Typography></OverflowTip>
                    </Paper>
                  </Paper>
                  <IconButton size="medium" className={classes.iconbuttonarea} onClick={() => onClickInvoice()}>
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>

              {matched_with_po && isOpenInvoiceRef.current[index] ?
                <Grid
                  container
                  spacing={0}
                  justifyContent="space-around"
                  style={{ paddingTop: 'inherit', maxWidth: "fit-content", minWidth: "100%" }}
                >
                  <Grid container className={classes.orderHeaderContainer}>
                    <Grid item style={{ width: "10%" }}><Typography noWrap component="div"><b><OverflowTip>Order No.</OverflowTip></b></Typography></Grid>
                    <Grid item style={{ width: "16%" }}><Typography noWrap component="div"><b><OverflowTip>Date</OverflowTip></b></Typography></Grid>
                    <Grid item style={{ width: "25%" }}><Typography noWrap component="div"><b><OverflowTip>Supplier</OverflowTip></b></Typography></Grid>
                    <Grid item style={{ width: "25%" }}><Typography noWrap component="div"><b><OverflowTip>Customer</OverflowTip></b></Typography></Grid>
                    <Grid item style={{ width: "14%" }}><Typography noWrap style={{ marginLeft: "10px" }} component="div"><b><OverflowTip>Status</OverflowTip></b></Typography></Grid>
                    <Grid item style={{ width: "10%" }}><Typography noWrap style={{ textAlign: "right", marginRight: "10px" }} component="div"><b><OverflowTip>Amount</OverflowTip></b></Typography></Grid>
                  </Grid>

                  {orders_data.map((value, index) => (
                    <Grid key={index} container className={classes.orderListContainer} style={{ background: "white", border: "1px solid #8aa9ca" }} >
                      <Grid item style={{ width: "10%" }}><Typography noWrap style={{ lineHeight: "30px" }} component="div"><OverflowTip>{value.orders_id.order_number}</OverflowTip></Typography></Grid>
                      <Grid item style={{ width: "16%" }}><Typography noWrap style={{ lineHeight: "30px" }} component="div"><OverflowTip>{value.orders_id.order_date ? moment(value.orders_id.order_date).format("DD/MM/YYYY") : ''}</OverflowTip></Typography></Grid>
                      <Grid item style={{ width: "25%" }}><Typography noWrap style={{ lineHeight: "30px" }} component="div"><OverflowTip>{value.orders_id.supplier ? value.orders_id.supplier.name : ''}</OverflowTip></Typography></Grid>
                      <Grid item style={{ width: "25%" }}><Typography noWrap style={{ lineHeight: "30px" }} component="div"><OverflowTip>{value.orders_id.farm_code ? value.orders_id.farm_code.name : ''}</OverflowTip></Typography></Grid>
                      <Grid item style={{ width: "14%" }} >
                        <Paper elevation={0} className={classes.itemcolumn} style={{ background: getBackgroundColor(value.orders_id.status) }} >
                          <Typography noWrap style={{ textTransform: "capitalize" }} component="div"><OverflowTip>{value.orders_id.status}</OverflowTip></Typography>
                        </Paper>
                      </Grid>
                      <Grid item style={{ width: "10%" }}>
                        <Typography noWrap style={{ lineHeight: "30px", textAlign: "right", marginRight: "10px" }} component="div"><OverflowTip>{isNaN(value.total_order) ? 0 : "$" + formatNumber(value.total_order.toFixed(2))}</OverflowTip></Typography>
                      </Grid>
                    </Grid>
                  ))}

                  <div style={{ marginBottom: "10px", width: "100%" }}></div>
                </Grid>
                : ""}
            </Paper>
          </div>
        </div>
      );
    }
    );
  }
}


const InvoiceList = (props) => {
  const [selectedApprovedInvoice, setSelectedApprovedInvoice, selectedApprovedInvoiceRef] = useStateRef([]);
  const [openApproved, setOpenApproved, openApprovedRef] = useStateRef(false);
  const [approvedLoading, setApprovedLoading] = useState(false);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [showCheckbox, setShowCheckbox, showCheckboxRef] = useStateRef(InvoiceAdminPermission() ? localStorage.isMultiSelect ? localStorage.isMultiSelect === "true" ? true : false : false : false);

  useEffect(() => {

    const onUnselectInvoice = (eventData) => {
      setSelectedApprovedInvoice([]);
    }
    const listener = EventEmitter.addListener('unselect_invoice', onUnselectInvoice);

    const onShowHideMultiSelect = (eventData) => {
      setSelectedApprovedInvoice([]);
      if (InvoiceAdminPermission()) {
        setShowCheckbox(eventData.value);
      } else {
        setShowCheckbox(false);
      }
    }

    const listener2 = EventEmitter.addListener('show_hide_multi_select', onShowHideMultiSelect);

    return () => {
      listener.remove();
      listener2.remove();
    }
  });

  const closeApprovedDialog = async () => {
    setOpenApproved(false);
  }


  const approvedInvoice = async () => {
    setApprovedLoading(true);

    try {
      var selected_invoice_data = linq.from(selectedApprovedInvoiceRef.current)
        .select(s => ({ id: s.id, invoice_status: s.invoice_status }))
        .toArray();

      await directus.updateItems('invoices', selected_invoice_data)

      // var selected_order_data = linq.from(selectedApprovedInvoiceRef.current)
      //   .select(s => ({ id: s.order, status: s.status }))
      //   .where(w => w.id !== null && w.id !== '')
      //   .toArray();

      // await directus.updateItems('orders', selected_order_data);

      await props.loadInvoices();
      setApprovedLoading(false);
      setOpenApproved(false);
      enqueueSnackbar("Data updated successfully", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
      setSelectedApprovedInvoice([]);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
      setApprovedLoading(false);
    }
  }

  return (
    <div>
      <Row
        key={"invoice_list"}
        loadInvoices={props.loadInvoices}
        items={props.invoiceData}
        fallback={""}
        setOpenApproved={setOpenApproved}
        selectedApprovedInvoice={selectedApprovedInvoiceRef.current}
        setSelectedApprovedInvoice={setSelectedApprovedInvoice}
        showCheckbox={showCheckboxRef.current}
        setShowCheckbox={setShowCheckbox}
        page={props.page}
        pageNumber={props.pageNumber}
        saveFilterData={props.saveFilterData}
        saveSortData={props.saveSortData}
        totalPage={props.totalPage}
      />
      <Dialog open={openApprovedRef.current} onClose={closeApprovedDialog}>
        <DialogTitle disableTypography={true} id="form-dialog-title"><Typography component="div" variant="h6"><h2>Approve Invoice</h2></Typography></DialogTitle>
        <DialogContent>

          <Typography>
            Are you sure you want to approve the selected invoice?<br />
            <span style={{ color: "green" }}>({selectedApprovedInvoiceRef.current.length} selected data)</span>
          </Typography>
        </DialogContent>

        <DialogActions style={{ height: "60px", marginRight: "5px" }}>
          {approvedLoading ?
            <CircularProgress className={classes.circular} />
            :
            <div>
              <CancelButton
                id="cancel_btn"
                size="small"
                variant="contained"
                onClick={closeApprovedDialog}
              >
                Cancel
              </CancelButton>
              <SubmitButton
                id="save_btn"
                size="small"
                variant="contained"
                onClick={() => approvedInvoice()}
              >
                Approved
              </SubmitButton>
            </div>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}



export default InvoiceList;