import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { SnackbarProvider } from 'notistack';

window.addEventListener('click', (e) => {
  if (e.target.contains(document.querySelector('.cols-seperator'))) {
    if (document.querySelector('.cols-seperator')) {
      if (document.querySelector('.left-container')) {
        var left_width = document.querySelector('.left-container').style.width.split('px').join('');
        var right_width = document.querySelector('.right-container').style.width.split('px').join('');
        var total_width = parseInt(left_width) + parseInt(right_width);
        localStorage.setItem("left_width", ((left_width / total_width) * 100) + "%")
        localStorage.setItem("right_width", (((right_width - 30) / total_width) * 100) + "%")
      }
    }
  }

}, false);

ReactDOM.render((
  <BrowserRouter>
    <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} autoHideDuration={3200}>
      <App />
    </SnackbarProvider>
  </BrowserRouter>
), document.getElementById('root'));

serviceWorker.unregister();
