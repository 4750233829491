import moment from 'moment';

export function getCache(key, defaultValue = null) {
  try {
    var value = localStorage.getItem(`_cache_${key}`);
    if (value !== null) {
      value = decrypt(value);
      return isJson(value) ? JSON.parse(value) : value;
    }
  } catch (e) {
    console.log(e);
  }

  return defaultValue;
}

export function setCache(key, value) {
  try {
    var value = value;
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }
    value = encrypt(value);
    localStorage.setItem(`_cache_${key}`, value);
    if (getCache('expire') === null) {
      setCache(
        'expire',
        moment()
          .add(30, 'minute')
          .format()
      );
    }
  } catch (e) {
    console.log(e);
  }
}

export function removeCache(key) {
  localStorage.removeItem(`_cache_${key}`);
}

export function clearCache() {
  // Remove all keys that start with _cache_
  var keys = [];
  for (var i = 0; i < localStorage.length; i++) {
    if (localStorage.key(i).indexOf('_cache_') === 0) {
      keys.push(localStorage.key(i));
    }
  }

  keys.forEach(key => {
    localStorage.removeItem(key);
  });
}

export function isCacheExpired() {
  const expire = getCache('expire');
  if (expire === null) {
    return true;
  }

  if (moment(expire).isBefore(moment())) {
    return true;
  }

  return false;
}

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

function encrypt(value) {
  let buff = Buffer.from(value, 'utf-8');
  let base64data = buff.toString('base64');
  return base64data;
}

function decrypt(value) {
  let buff = Buffer.from(value, 'base64');
  value = buff.toString('utf-8');
  return value;
}

String.prototype.hashCode = function() {
  var hash = 0,
    i,
    chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

if (isCacheExpired()) {
  clearCache();
}

setInterval(() => {
  if (isCacheExpired()) {
    clearCache();
  }
}, 30 * 1000);
