import React, { useState, useEffect } from 'react';
import {
  withStyles,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Typography,
  Grid,
  MenuItem,
  LinearProgress
} from '@material-ui/core';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import directus from '../../services/directus';
import { CancelButton, SubmitButton } from 'src/components/ReusableStyle';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { useStylesEmployees, AddButton } from './style.js';
import useStateRef from "react-usestateref";
import { pageOptions } from 'src/_utils/pageOptions';
import { useSnackbar } from 'notistack';
import ErrorMessage from 'src/views/Components/ErrorMessage';
// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;
import SortRoundedIcon from '@material-ui/icons/SortRounded';
import Autocomplete from '@material-ui/lab/Autocomplete';
import linq from "linq";
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderColor: 'red',
      backgroundColor: 'white',
      borderRadius: '6px',
      height: '53.63px'
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl': {
      marginTop: '8px'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: "translate(14px, -12px) scale(0.75)"
    }
  },
})(TextField);

// const CustomTooltip = withStyles({
//   tooltip: {
//     fontSize: "16px",
//     maxWidth: 500,
//     backgroundColor: "grey"
//   }
// })(Tooltip);

let page = 1;

const AccountingSystems = (props) => {
  /* eslint-disable no-unused-vars*/
  const classes = useStylesEmployees();
  const [initialized, setInitialized] = useState(false);
  const [suppliers, setSuppliers] = useState(null);

  const [totalPage, setTotalPage] = useState('');
  const [pageNumber, setPageNumber, pageNumberRef] = useStateRef(100);

  const [loadingPagination, setLoadingPagination] = useState(false);
  const [dataSupplier, setDataSupplier, dataSupplierRef] = useStateRef({
    id: '', name: '', email: ''
  })

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [mode, setMode] = useState('');

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [sortField, setSortField, sortFieldRef] = useStateRef({
    "name": { type: "desc", value: false },
    "email": { type: "desc", value: false },
    "integration": { type: "desc", value: false },
    "integration_2": { type: "desc", value: false },
  });

  const [sortLoading, setSortLoading, sortLoadingRef] = useStateRef(false);

  const [integrationData, setIntegrationData] = useState(null);

  const [selectedIntegration, setSelectedIntegration] = React.useState('');
  const [selectedIntegration_2, setSelectedIntegration_2] = React.useState('');
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadFirst();
      loadIntegration();
    }
  });
  /* eslint-enable react-hooks/exhaustive-deps*/

  let disable = dataSupplierRef.current.name === '' || dataSupplierRef.current.email === '';

  const loadFirst = () => {
    var sort_data = localStorage.sort_data;
    if (sort_data) {
      sort_data = JSON.parse(sort_data);
      if (sort_data.accounting_systems) {
        setSortField(sort_data.accounting_systems)
      }
    }
    loadAccountingSystems();
  }

  const loadAccountingSystems = async (data) => {
    let filterData;

    var orderByField = "accounting_systems.name";

    var name_sort = sortFieldRef.current.name;
    if (name_sort.value) {
      if (name_sort.type === "asc") { orderByField = "accounting_systems.name"; }
      else if (name_sort.type === "desc") { orderByField = "-accounting_systems.name"; }
    }

    var email_sort = sortFieldRef.current.email;
    if (email_sort.value) {
      if (email_sort.type === "asc") { orderByField = "accounting_systems.email"; }
      else if (email_sort.type === "desc") { orderByField = "-accounting_systems.email"; }
    }

    var integration_sort = sortFieldRef.current.integration;
    if (integration_sort.value) {
      if (integration_sort.type === "asc") { orderByField = "integration.name"; }
      else if (integration_sort.type === "desc") { orderByField = "-integration.name"; }
    }

    filterData = {
      fields: '*,integration.*,integration_2.*',
      limit: pageNumberRef.current,
      page: page,
      meta: "filter_count",
      sort: orderByField
    };


    var EmployeesResults = await directus.api.get('/custom/settings/accounting_systems', filterData);

    setSuppliers(EmployeesResults.data);
    setTotalPage(EmployeesResults.meta.filter_count);


    let SortOrder;
    let sort_type = '';
    let sort_by_data = '';
    let changeUpperCase = false;

    if (name_sort.value) {
      sort_by_data = 'name';
      sort_type = name_sort.type;
      changeUpperCase = true;
    } else if (email_sort.value) {
      sort_by_data = 'email';
      sort_type = email_sort.type;
      changeUpperCase = true;
    } else if (integration_sort.value) {
      sort_by_data = 'integration_name';
      sort_type = integration_sort.type;
      changeUpperCase = true;
    } else {
      sort_by_data = 'name';
      sort_type = 'asc';
      changeUpperCase = true;
    }


    SortOrder = EmployeesResults.data;
    SortOrder.map((value, index) => {
      var integration_name = "";
      if (value.integration !== null) {
        if (value.integration.name) {
          integration_name = value.integration.name;
        }
      }
      SortOrder[index].integration_name = integration_name;
    })

    SortOrder = linq.from(SortOrder)
      .orderBy(o => o.name)
      .toArray();

    if (sort_by_data) {
      if (sort_type === "desc") {
        SortOrder.sort(function (a, b) {
          let nameA = a[sort_by_data] ? a[sort_by_data] : '';
          let nameB = b[sort_by_data] ? b[sort_by_data] : '';
          if (changeUpperCase) {
            nameA = nameA ? nameA.toUpperCase() : nameA; // ignore upper and lowercase
            nameB = nameB ? nameB.toUpperCase() : nameB; // ignore upper and lowercase
          }

          if (nameA > nameB) { return -1; }
          if (nameA < nameB) { return 1; }
          return 0; // names must be equal
        });
      } else {
        SortOrder.sort(function (a, b) {
          let nameA = a[sort_by_data] ? a[sort_by_data] : '';
          let nameB = b[sort_by_data] ? b[sort_by_data] : '';
          if (changeUpperCase) {
            nameA = nameA ? nameA.toUpperCase() : nameA; // ignore upper and lowercase
            nameB = nameB ? nameB.toUpperCase() : nameB; // ignore upper and lowercase
          }

          if (nameA < nameB) { return -1; }
          if (nameA > nameB) { return 1; }
          return 0; // names must be equal
        });
      }
    }

    if (SortOrder) {
      setSuppliers(SortOrder);
    }

    setLoadingPagination(false);
    setSortLoading(false);
  };

  const loadIntegration = async () => {
    try {
      var integration_data = await directus.getItems('integration');
      setIntegrationData(integration_data.data);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
  };

  const handleClickOpen = (supplier, mode) => {
    setOpen(true);
    if (mode === "add") {
      setMode('add');
      setTitle('Add Accounting Systems');
      setText('');
    }
    else if (mode === "edit") {
      setDataSupplier({ id: supplier.id, name: supplier.name, email: supplier.email })
      setSelectedIntegration(supplier.integration);
      setSelectedIntegration_2(supplier.integration_2);
      setMode('edit');
      setTitle('Edit Accounting Systems');
      setText('');
    } else {
      setDataSupplier({ id: supplier.id, name: supplier.name, email: supplier.email })
      setMode('delete');
      setTitle('Delete Accounting Systems');
      setText('Are you sure?');
    }
  }

  const handleClose = () => {
    setOpen(false);
    setDataSupplier({ id: '', name: '', email: '' })
    setSelectedIntegration('');
    setSelectedIntegration_2('');
  };

  const handleChangeInput = (e, type) => {
    if (type === "integration") {
      setSelectedIntegration(e);
    } else if (type === "integration_2") {
        setSelectedIntegration_2(e);
    } else if (type === "email") {
      const { name, value } = e.target;
      var check_value = null;
      if (value) {
        if (value === " ") {
          check_value = "";
        } else {
          check_value = value.split(/[ ,]+/).join(';');
          check_value = check_value.split(/[;;,]+/).join(';');
        }
      }
      setDataSupplier({ ...dataSupplierRef.current, [name]: check_value })
    } else {
      const { name, value } = e.target;
      setDataSupplier({ ...dataSupplierRef.current, [name]: value })
    }
  }

  const SaveData = async () => {
    setLoading(true);
    if (mode === 'edit') {
      try {
        var new_data_edit = dataSupplierRef.current;
        var body_edit = {
          id: new_data_edit.id,
          name: new_data_edit.name,
          email: new_data_edit.email,
          integration: selectedIntegration ? selectedIntegration.id : null,
          integration_2: selectedIntegration_2 ? selectedIntegration_2.id : null,
          status: 'published'
        }

        await directus.updateItem('accounting_systems', body_edit.id, body_edit);
        enqueueSnackbar("Data updated successfully", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
      }
    }
    else if (mode === 'add') {
      try {
        var new_data = dataSupplierRef.current;
        var body = {
          name: new_data.name,
          email: new_data.email,
          integration: selectedIntegration ? selectedIntegration.id : '',
          integration_2: selectedIntegration_2 ? selectedIntegration_2.id : '',
          status: 'published'
        }
        await directus.createItem('accounting_systems', body);
        enqueueSnackbar("Data updated successfully", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
      }
    }
    else {
      try {
        await directus.deleteItem('accounting_systems', dataSupplierRef.current.id);
        enqueueSnackbar("Data deleted successfully", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
      }
    }

    loadAccountingSystems();
    setLoading(false);
    handleClose();
  }


  const pageChange = (value) => {
    setLoadingPagination(true);
    page = page + value;
    loadAccountingSystems();
  }

  const handleChange = (event) => {
    setPageNumber(event.target.value);
    page = 1;
    loadAccountingSystems();
  };

  const sortData = (val) => {
    setSortLoading(true);
    var sort_field_data = {
      "name": { type: "desc", value: false },
      "email": { type: "desc", value: false },
      "integration": { type: "desc", value: false },
      "integration_2": { type: "desc", value: false },
    }

    var sort_data = sortFieldRef.current[val];
    if (sort_data) {
      var value = sort_data.value;
      if (sort_data.value === false) {
        value = true;
      }

      if (value === true) {

        var sort_type = "";
        if (sort_data.type === "desc") {
          sort_type = "asc";
        } else {
          sort_type = "desc";
        }

        sort_field_data[val].value = true;
        sort_field_data[val].type = sort_type;

        var sort_data = localStorage.sort_data;
        if (sort_data) {
          sort_data = JSON.parse(sort_data);
          sort_data.accounting_systems = sort_field_data;
        } else {
          sort_data = { accounting_systems: sort_field_data };
        }

        localStorage.setItem("sort_data", JSON.stringify(sort_data))
        setSortField(sort_field_data);
      }
    }

    loadAccountingSystems();
  }

  return (
    <div >
      {suppliers ?
        <div >
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{ backgroundColor: '#f2f2f6' }}>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}>
                      <div className={classes.headerSort} onClick={() => sortData("name")}>
                        <Typography noWrap variant="h5">Name</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['name'].value === true ?
                            sortFieldRef.current['name'].type === "asc" ?
                              <SortRoundedIcon className={classes.ascSort} />
                              :
                              <SortRoundedIcon className={classes.descSort} />
                            :
                            ''
                          }
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className={classes.headerSort} onClick={() => sortData("email")}>
                        <Typography noWrap variant="h5">Email</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['email'].value === true ?
                            sortFieldRef.current['email'].type === "asc" ?
                              <SortRoundedIcon className={classes.ascSort} />
                              :
                              <SortRoundedIcon className={classes.descSort} />
                            :
                            ''
                          }
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className={classes.headerSort} onClick={() => sortData("integration")}>
                        <Typography noWrap variant="h5">Integration</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['integration'].value === true ?
                            sortFieldRef.current['integration'].type === "asc" ?
                              <SortRoundedIcon className={classes.ascSort} />
                              :
                              <SortRoundedIcon className={classes.descSort} />
                            :
                            ''
                          }
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className={classes.headerSort} onClick={() => sortData("integration_2")}>
                        <Typography noWrap variant="h5">Integration 2</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['integration_2'].value === true ?
                            sortFieldRef.current['integration_2'].type === "asc" ?
                              <SortRoundedIcon className={classes.ascSort} />
                              :
                              <SortRoundedIcon className={classes.descSort} />
                            :
                            ''
                          }
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                }
              />
              <Grid item xs={2} style={{ textAlign: 'right' }}>
                {props.config_settings === true ?
                  <AddButton id="add_btn" size="small" color="secondary" aria-label="add" onClick={() => handleClickOpen('', 'add')}>
                    <AddIcon />
                  </AddButton>
                  : ''}
              </Grid>

            </ListItem>
          </List>
          {sortLoadingRef.current ?
            <Grid item xs={12}>
              <div className={classes.linearProgress} style={{ marginLeft: "7px", marginRight: "7px" }}><LinearProgress style={{ height: "2px" }} /></div>
            </Grid>
            : ''}
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{ marginBottom: '20px' }} >

            {/* Area Supplier */}

            {suppliers.map((supplier, index) => {
              return (
                <div key={index}>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Grid container key={index}>
                          <Grid item xs={3} style={{ overflowX: "hidden", textOverflow: "clip", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6">{supplier.name ? supplier.name : ''}</Typography>
                          </Grid>
                          <Grid item xs={3} style={{ overflowX: "hidden", textOverflow: "clip", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6">{supplier.email}</Typography>
                          </Grid>
                          <Grid item xs={3} style={{ overflowX: "hidden", textOverflow: "clip", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6">{supplier.integration ? supplier.integration.name ? supplier.integration.name : supplier.integration.id : ''}</Typography>
                          </Grid>
                          <Grid item xs={3} style={{ overflowX: "hidden", textOverflow: "clip", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6">{supplier.integration_2 ? supplier.integration_2.name ? supplier.integration_2.name : supplier.integration_2.id : ''}</Typography>
                          </Grid>
                        </Grid>
                      }
                    />

                    {props.config_settings === true ?
                      <Grid item xs={2} style={{ textAlign: 'right' }}>
                        <IconButton id={`edit_btn_${index}`} aria-label="edit" onClick={() => handleClickOpen(supplier, 'edit')} >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton id={`delete_btn_${index}`} aria-label="delete" onClick={() => handleClickOpen(supplier, 'delete')} >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                      : <Grid item xs={2} style={{ textAlign: 'right' }}></Grid>}
                    {/* <IconButton aria-label="edit" 
                          // onClick={() => handleClickOpen(supplier)}
                         >
                          <EditIcon fontSize="small" />
                        </IconButton> */}

                  </ListItem>
                  {suppliers.length - 1 > index ? <Divider /> : ''}
                </div>
              );
            })}

            <Dialog
              id="holiday_dialog"
              isopen={`${open}`}
              open={open}
              fullWidth={true}
              onClose={handleClose}
            >
              <DialogTitle disableTypography={true} id="form-dialog-title"><Typography id="holiday_dialog_title" component="div" variant="h6"><h2>{title}</h2></Typography></DialogTitle>
              <DialogContent>
                <Typography variant="h6">{text ? text : ''}</Typography>
                {mode !== 'delete' ?
                  <div>
                    <TextField
                      id="name_text"
                      label="Name"
                      variant="outlined"
                      fullWidth={true}
                      margin="normal"
                      name="name"
                      value={dataSupplierRef.current.name}
                      onChange={handleChangeInput}
                    />
                    <TextField
                      id="email_text"
                      label="Email"
                      variant="outlined"
                      fullWidth={true}
                      margin="normal"
                      name="email"
                      value={dataSupplierRef.current.email}
                      onChange={(e) => handleChangeInput(e, "email")}
                    />
                    <Autocomplete
                      id="select-integration"
                      size="small"
                      options={integrationData ? integrationData : []}
                      getOptionLabel={(option) => option.name ? option.name : option.id}
                      style={{ marginTop: '15px' }}
                      value={selectedIntegration ? selectedIntegration : null}
                      onChange={(event, newValue) => {
                        handleChangeInput(newValue, "integration");
                      }}
                      renderInput={(params) =>
                        <CssTextField
                          {...params}
                          variant="outlined"
                          name="integration"
                          label="Select Integration"
                        />}
                    />
                    <Autocomplete
                      id="select-integration-2"
                      size="small"
                      options={integrationData ? integrationData : []}
                      getOptionLabel={(option) => option.name ? option.name : option.id}
                      style={{ marginTop: '25px' }}
                      value={selectedIntegration_2 ? selectedIntegration_2 : null}
                      onChange={(event, newValue) => {
                        handleChangeInput(newValue, "integration_2");
                      }}
                      renderInput={(params) =>
                        <CssTextField
                          {...params}
                          variant="outlined"
                          name="integration"
                          label="Select Integration 2"
                        />}
                    />
                  </div>
                  : ''}
              </DialogContent>
              <DialogActions style={{ height: "60px" }}>
                {loading ?
                  <CircularProgress className={classes.circular} />
                  :
                  <div>
                    <CancelButton
                      id="cancel_btn"
                      size="small"
                      variant="contained"
                      onClick={handleClose}
                    >
                      Cancel
                    </CancelButton>
                    <SubmitButton
                      id="save_btn"
                      disabled={mode === 'delete' ? false : disable}
                      size="small"
                      variant="contained"
                      onClick={() => SaveData()}
                    >
                      {mode === 'delete' ? 'Delete' : 'Save'}
                    </SubmitButton>
                  </div>
                }

              </DialogActions>
            </Dialog>
          </List>
        </div>
        :
        <CircularProgress className={classes.circular} />
      }

      {suppliers ?
        <Grid
          item xs={12}
          style={{ textAlign: 'right', marginBottom: '10px', fontFamily: 'SF Pro' }}
        >
          <span style={{ paddingRight: '20px' }}>
            Rows per page:
          </span>
          <span style={{ paddingRight: '20px' }}>
            <TextField
              id="standard-select-currency"
              select
              value={pageNumber}
              onChange={handleChange}
            >
              {pageOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </span>

          <span style={{ paddingRight: '20px' }}>
            {(pageNumberRef.current * (page - 1)) + 1} - {(pageNumberRef.current * page) > totalPage ? totalPage : pageNumberRef.current * page} of {totalPage}
          </span>

          <Button
            variant="outlined"
            size="small"
            disabled={page === 1 || loadingPagination}
            className={classes.buttonLeft}
            onClick={() => pageChange(-1)}
          >
            <ArrowBackIosRoundedIcon />
          </Button>

          <Button
            variant="outlined"
            size="small"
            className={classes.buttonRight}
            disabled={page >= Math.ceil(totalPage / pageNumberRef.current) || loadingPagination}
            onClick={() => pageChange(1)}
          >
            <ArrowForwardIosRoundedIcon />
          </Button>
        </Grid>
        :
        " "}
    </div>
  );
}

export default AccountingSystems;