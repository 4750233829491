import React, { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Typography,
  Grid,
  MenuItem,
  LinearProgress,
  Divider
} from '@material-ui/core';
import { CancelButton, SubmitButton } from 'src/components/ReusableStyle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import directus from '../../services/directus';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { useStylesEmployees, AddButton, CssTextField } from './style.js';
import useStateRef from 'react-usestateref';
import { pageOptions } from 'src/_utils/pageOptions';
import { useSnackbar } from 'notistack';
import ErrorMessage from 'src/views/Components/ErrorMessage';
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';
import { removeCache } from '../../utils/KeyValueCache';

let page = 1;

const Integration = props => {
  /* eslint-disable no-unused-vars*/
  const classes = useStylesEmployees();
  const [initialized, setInitialized] = useState(false);
  const [integration, setIntegrationData] = useState(null);
  const [type, setType] = useState('');
  const [integrationType, setIntegrationType] = useState('');
  const [totalPage, setTotalPage] = useState('');
  const [pageNumber, setPageNumber, pageNumberRef] = useStateRef(100);

  const [loadingPagination, setLoadingPagination] = useState(false);
  const [dataItem, setDataItem, dataItemRef] = useStateRef({
    id: '',
    name: '',
    type: '',
    environment: '',
    tenant_id: '',
    client_id: '',
    client_secret: ''
  });

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [mode, setMode] = useState('');

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [sortLoading, setSortLoading, sortLoadingRef] = useStateRef(false);

  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadIntegration();
      loadIntegrationType();
    }
  });
  /* eslint-enable react-hooks/exhaustive-deps*/

  const loadIntegration = async () => {
    try {
      let filterData = {
        limit: pageNumberRef.current,
        page: page,
        meta: 'filter_count'
      };

      var IntegrationResult = await directus.getItems('integration', filterData);

      setIntegrationData(IntegrationResult.data);
      setTotalPage(IntegrationResult.meta.filter_count);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }

    setLoadingPagination(false);
    setSortLoading(false);
  };

  const loadIntegrationType = async () => {
    try {
      let integrationResult = [];
      var integration_data = await directus.getField('integration', 'type');

      const integration_list = Object.entries(integration_data.data.options.choices);
      integration_list.map((data, index) => {
        integrationResult[index] = { id: data[0], name: data[1], value: data[0] };
      });

      setIntegrationType(integrationResult);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
  };

  const handleClickOpen = (integration, mode) => {
    setOpen(true);
    if (mode === 'add') {
      setType({ id: 0, name: 'Business Central 365', value: 'bc365' });
      setMode('add');
      setTitle('Add Integration');
      setText('');
    } else if (mode === 'edit') {
      setDataItem({
        id: integration.id,
        name: integration.name,
        type: integration.type,
        environment: integration.environment,
        tenant_id: integration.tenant_id,
        client_id: integration.client_id,
        client_secret: integration.client_secret
      });

      if (integrationType.length > 0) {
        let result = [];
        integrationType.map(data => {
          if (data.id === integration.type) {
            result = { id: data.id, name: data.name, value: data.value };
          }
        });
        setType(result);
      } else {
        setType('');
      }

      setMode('edit');
      setTitle('Edit Integration');
      setText('');
    } else {
      setDataItem({
        id: integration.id,
        name: integration.name,
        type: integration.type,
        environment: integration.environment,
        tenant_id: integration.tenant_id,
        client_id: integration.client_id,
        client_secret: integration.client_secret
      });
      setMode('delete');
      setTitle('Delete Integration');
      setText('Are you sure?');
    }
  };

  const handleClose = () => {
    setOpen(false);
    setDataItem({
      id: '',
      name: '',
      type: '',
      environment: '',
      tenant_id: '',
      client_id: '',
      client_secret: ''
    });
  };

  const handleChangeInput = (e, type) => {
    const { name, value } = e.target;
    setDataItem({ ...dataItemRef.current, [name]: value });
  };

  const SaveData = async () => {
    setLoading(true);
    try {
      if (mode === 'edit') {
        try {
          var new_data_edit = dataItemRef.current;
          var body_edit = {
            id: new_data_edit.id,
            name: new_data_edit.name,
            type: type ? type.value : '',
            environment: new_data_edit.environment,
            tenant_id: new_data_edit.tenant_id,
            client_id: new_data_edit.client_id,
            client_secret: new_data_edit.client_secret
          };
          await directus.updateItem('integration', body_edit.id, body_edit);
          enqueueSnackbar('Data updated successfully', {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: 'success',
            autoHideDuration: 3200,
            action: CloseSnackbarAction
          });
        } catch (e) {
          enqueueSnackbar(ErrorMessage(e), {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: 'error',
            autoHideDuration: 3200,
            action: CloseSnackbarAction
          });
        }
      } else if (mode === 'add') {
        try {
          var new_data = dataItemRef.current;
          var body = {
            name: new_data.name,
            type: type ? type.value : '',
            environment: new_data.environment,
            tenant_id: new_data.tenant_id,
            client_id: new_data.client_id,
            client_secret: new_data.client_secret
          };
          await directus.createItem('integration', body);
          enqueueSnackbar('Data updated successfully', {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: 'success',
            autoHideDuration: 3200,
            action: CloseSnackbarAction
          });
        } catch (e) {
          enqueueSnackbar(ErrorMessage(e), {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: 'error',
            autoHideDuration: 3200,
            action: CloseSnackbarAction
          });
        }
      } else {
        try {
          await directus.deleteItem('integration', dataItemRef.current.id);
          enqueueSnackbar('Data deleted successfully', {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: 'success',
            autoHideDuration: 3200,
            action: CloseSnackbarAction
          });
        } catch (e) {
          enqueueSnackbar(ErrorMessage(e), {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: 'error',
            autoHideDuration: 3200,
            action: CloseSnackbarAction
          });
        }
      }

      removeCache('integration');
      loadIntegration();
      setLoading(false);
      handleClose();
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
      setLoading(false);
    }
  };

  const pageChange = value => {
    setLoadingPagination(true);
    page = page + value;
    loadIntegration();
  };

  const handleChange = event => {
    setPageNumber(event.target.value);
    page = 1;
    loadIntegration();
  };

  let disable = type === ''
                || (dataItemRef.current && dataItemRef.current.name === '')
                || (dataItemRef.current && dataItemRef.current.environment === '')
                || (dataItemRef.current && dataItemRef.current.tenant_id === '')
                || (dataItemRef.current && dataItemRef.current.client_id === '')
                || (dataItemRef.current && dataItemRef.current.client_secret === '');

  return (
    <div>
      {integration ? (
        <div>
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{ backgroundColor: '#f2f2f6' }}>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3} style={{ overflowX: 'hidden', textOverflow: 'ellipsis', paddingRight: '5px' }}>
                      <Typography noWrap variant="h5">
                        Name
                      </Typography>
                    </Grid>
                    <Grid item xs={1} style={{ overflowX: 'hidden', textOverflow: 'ellipsis', paddingRight: '5px' }}>
                      <Typography noWrap variant="h5">
                        Type
                      </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ overflowX: 'hidden', textOverflow: 'ellipsis', paddingRight: '5px' }}>
                      <Typography noWrap variant="h5">
                        Environment
                      </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ overflowX: 'hidden', textOverflow: 'ellipsis', paddingRight: '5px' }}>
                      <Typography noWrap variant="h5">
                        Tenant ID
                      </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ overflowX: 'hidden', textOverflow: 'ellipsis', paddingRight: '5px' }}>
                      <Typography noWrap variant="h5">
                        Client ID
                      </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ overflowX: 'hidden', textOverflow: 'ellipsis', paddingRight: '5px' }}>
                      <Typography noWrap variant="h5">
                        Client Secret
                      </Typography>
                    </Grid>
                  </Grid>
                }
              />
              <Grid item xs={1} style={{ textAlign: 'right' }}>
                <AddButton id="add_btn" size="small" color="secondary" aria-label="add" onClick={() => handleClickOpen('', 'add')}>
                  <AddIcon />
                </AddButton>
              </Grid>
            </ListItem>
          </List>
          {sortLoadingRef.current ? (
            <Grid item xs={12}>
              <div className={classes.linearProgress} style={{ marginLeft: '7px', marginRight: '7px' }}>
                <LinearProgress style={{ height: '2px' }} />
              </div>
            </Grid>
          ) : (
            ''
          )}
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{ marginBottom: '20px' }}>
            {integration.map((item, index) => {
              return (
                <div key={index}>
                  <ListItem
                    style={{
                      backgroundColor:
                        item.status === 'draft'
                          ? '#EBE9E9'
                          : item.status === 'inactive'
                          ? '#cdd0d1'
                          : item.status === 'deleted'
                          ? '#fee1e0a1'
                          : 'white'
                    }}
                  >
                    <ListItemText
                      primary={
                        <Grid container key={index}>
                          <Grid
                            item
                            xs={3}
                            style={{ overflowX: 'hidden', textOverflow: 'ellipsis', overflowWrap: 'break-word', paddingRight: '5px' }}
                          >
                            <Typography variant="h6">{item.name ? item.name : ''}</Typography>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{ overflowX: 'hidden', textOverflow: 'ellipsis', overflowWrap: 'break-word', paddingRight: '5px' }}
                          >
                            <Typography variant="h6">{item.type ? item.type : ''}</Typography>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{ overflowX: 'hidden', textOverflow: 'ellipsis', overflowWrap: 'break-word', paddingRight: '5px' }}
                          >
                            <Typography variant="h6">{item.environment ? item.environment : ''}</Typography>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{ overflowX: 'hidden', textOverflow: 'ellipsis', overflowWrap: 'break-word', paddingRight: '5px' }}
                          >
                            <Typography variant="h6">{item.tenant_id ? item.tenant_id : ''}</Typography>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{ overflowX: 'hidden', textOverflow: 'ellipsis', overflowWrap: 'break-word', paddingRight: '5px' }}
                          >
                            <Typography variant="h6">{item.client_id ? item.client_id : ''}</Typography>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{ overflowX: 'hidden', textOverflow: 'ellipsis', overflowWrap: 'break-word', paddingRight: '5px' }}
                          >
                            <Typography variant="h6">{item.client_secret ? item.client_secret : ''}</Typography>
                          </Grid>
                        </Grid>
                      }
                    />

                    <Grid item xs={1} style={{ textAlign: 'right' }}>
                      <IconButton id={`edit_btn_${index}`} aria-label="edit" onClick={() => handleClickOpen(item, 'edit')}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton id={`delete_btn_${index}`} aria-label="delete" onClick={() => handleClickOpen(item, 'delete')}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  </ListItem>
                  {integration.length - 1 > index ? <Divider /> : ''}
                </div>
              );
            })}
          </List>
        </div>
      ) : (
        <CircularProgress className={classes.circular} />
      )}

      <Dialog id="holiday_dialog" isopen={`${open}`} open={open} fullWidth={true} onClose={handleClose}>
        <DialogTitle disableTypography={true} id="form-dialog-title">
          <Typography id="holiday_dialog_title" component="div" variant="h6">
            <h2>{title}</h2>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6">{text ? text : ''}</Typography>
          {mode !== 'delete' ? (
            <div>
              <TextField
                id="name_text"
                label="Name"
                variant="outlined"
                fullWidth={true}
                margin="normal"
                name="name"
                value={dataItemRef.current.name}
                onChange={handleChangeInput}
              />
              {integrationType ? (
                <Autocomplete
                  id="size-small-standard"
                  size="small"
                  options={integrationType ? integrationType : []}
                  getOptionLabel={option => option.name ?? ''}
                  getOptionSelected={(option, value) => option.value === value.value}
                  value={type}
                  name="type"
                  onChange={(event, newValue) => {
                    newValue === null ? setType('') : setType({ id: newValue.id, name: newValue.name, value: newValue.value });
                  }}
                  renderInput={params => <CssTextField {...params} variant="outlined" name="type" label="Select type" />}
                />
              ) : (
                ''
              )}
              <TextField
                id="name_text"
                label="Environment"
                variant="outlined"
                fullWidth={true}
                margin="normal"
                name="environment"
                value={dataItemRef.current.environment}
                onChange={handleChangeInput}
              />
              <TextField
                id="field_text"
                label="Tenant ID"
                variant="outlined"
                fullWidth={true}
                margin="normal"
                name="tenant_id"
                value={dataItemRef.current.tenant_id}
                onChange={handleChangeInput}
              />
              <TextField
                id="field_text"
                label="Client ID"
                variant="outlined"
                fullWidth={true}
                margin="normal"
                name="client_id"
                value={dataItemRef.current.client_id}
                onChange={handleChangeInput}
              />
              <TextField
                id="field_text"
                label="Client Secret"
                variant="outlined"
                fullWidth={true}
                margin="normal"
                name="client_secret"
                value={dataItemRef.current.client_secret}
                onChange={handleChangeInput}
              />
            </div>
          ) : (
            ''
          )}
        </DialogContent>
        <DialogActions style={{ height: '60px' }}>
          {loading ? (
            <CircularProgress className={classes.circular} />
          ) : (
            <div>
              <CancelButton id="cancel_btn" size="small" variant="contained" onClick={handleClose}>
                Cancel
              </CancelButton>
              <SubmitButton
                id="save_btn"
                style={{ marginLeft: '10px' }}
                disabled={mode === 'delete' ? false : disable}
                size="small"
                variant="contained"
                onClick={() => SaveData()}
              >
                {mode === 'delete' ? 'Delete' : 'Save'}
              </SubmitButton>
            </div>
          )}
        </DialogActions>
      </Dialog>

      {integration ? (
        <Grid item xs={12} style={{ textAlign: 'right', marginBottom: '10px', fontFamily: 'SF Pro' }}>
          <span style={{ paddingRight: '20px' }}>Rows per page:</span>
          <span style={{ paddingRight: '20px' }}>
            <TextField id="standard-select-currency" select value={pageNumber} onChange={handleChange}>
              {pageOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </span>

          <span style={{ paddingRight: '20px' }}>
            {pageNumberRef.current * (page - 1) + 1} - {pageNumberRef.current * page > totalPage ? totalPage : pageNumberRef.current * page} of{' '}
            {totalPage}
          </span>

          <Button
            variant="outlined"
            size="small"
            disabled={page === 1 || loadingPagination}
            className={classes.buttonLeft}
            onClick={() => pageChange(-1)}
          >
            <ArrowBackIosRoundedIcon />
          </Button>

          <Button
            variant="outlined"
            size="small"
            className={classes.buttonRight}
            disabled={page >= Math.ceil(totalPage / pageNumberRef.current) || loadingPagination}
            onClick={() => pageChange(1)}
          >
            <ArrowForwardIosRoundedIcon />
          </Button>
        </Grid>
      ) : (
        ' '
      )}
    </div>
  );
};

export default Integration;
