import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { formatNumber } from 'src/components/ReusableFunction';
import {
  withStyles,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Typography,
  Grid,
  MenuItem,
  LinearProgress,
  Divider
} from '@material-ui/core';
import { CancelButton, SubmitButton } from 'src/components/ReusableStyle';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import directus from '../../services/directus';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { useStylesEmployees, AddButton } from './style.js';
import useStateRef from "react-usestateref";
import { pageOptions } from 'src/_utils/pageOptions';
import { useSnackbar } from 'notistack';
import ErrorMessage from 'src/views/Components/ErrorMessage';
import SortRoundedIcon from '@material-ui/icons/SortRounded';
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';
import { removeCache } from "../../utils/KeyValueCache";
import Autocomplete from '@material-ui/lab/Autocomplete';

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderColor: 'red',
      backgroundColor: 'white',
      borderRadius: '6px',
      height: '53.63px'
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl': {
      marginTop: '8px'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: "translate(14px, -12px) scale(0.75)"
    }
  },
})(TextField);

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

const levelOptions = [
  { value: "1", name: "1" },
  { value: "2", name: "2" },
  { value: "3", name: "3" },
  { value: "4", name: "4" },
  { value: "5", name: "5" }
]

let page = 1;

const ApprovalLevel = (props) => {
  /* eslint-disable no-unused-vars*/
  const classes = useStylesEmployees();
  const [initialized, setInitialized] = useState(false);
  const [approvalLevel, setApprovalLevel] = useState(null);

  const [totalPage, setTotalPage] = useState('');
  const [pageNumber, setPageNumber, pageNumberRef] = useStateRef(100);

  const [loadingPagination, setLoadingPagination] = useState(false);
  const [approvalLevelData, setApprovalLevelData, approvalLevelDataRef] = useStateRef({
    id: '',  max_amount: '', level: ''
  })

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [mode, setMode] = useState('');

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [sortField, setSortField, sortFieldRef] = useStateRef({
    "max_amount": { type: "desc", value: false },
    "level": { type: "desc", value: false },
  });

  const [sortLoading, setSortLoading, sortLoadingRef] = useStateRef(false);

  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadFirst();
    }
  });
  /* eslint-enable react-hooks/exhaustive-deps*/

  const loadFirst = () => {
    var sort_data = localStorage.sort_data;
    if (sort_data) {
      sort_data = JSON.parse(sort_data);
      if (sort_data.approval_level) {
        setSortField(sort_data.approval_level)
      }
    }
    loadApprovalLevel();
  }
  
  let disable = approvalLevelDataRef.current.max_amount === 0 || approvalLevelDataRef.current.max_amount === '' || approvalLevelDataRef.current.level === '';

  const loadApprovalLevel = async (data) => {
    var max_amount_sort = sortFieldRef.current.max_amount;
    var level_sort = sortFieldRef.current.level;
    var orderByField = "level";

    if (max_amount_sort.value) {
      if (max_amount_sort.type === "asc") { orderByField = "max_amount"; }
      else if (max_amount_sort.type === "desc") { orderByField = "-max_amount"; }
    }

    if (level_sort.value) {
      if (level_sort.type === "asc") { orderByField = "level"; }
      else if (level_sort.type === "desc") { orderByField = "-level"; }
    }

    let filterData = {
      limit: pageNumberRef.current,
      page: page,
      filter: {
        status: { eq: 'published' },
      },
      meta: "filter_count",
      sort: orderByField
    };

    var ApprovalLevelResult = await directus.getItems('approval_level', filterData);

    setApprovalLevel(ApprovalLevelResult.data);
    setTotalPage(ApprovalLevelResult.meta.filter_count);
    setLoadingPagination(false);
    setSortLoading(false);
  };

  const handleClickOpen = (approval_level, mode) => {
    setOpen(true);
    if (mode === "add") {
      setMode('add');
      setTitle('Add Approval Level');
      setText('');
    }
    else if (mode === "edit") {
      setApprovalLevelData({
        id: approval_level.id,
        max_amount: approval_level.max_amount,
        level: levelOptions.find((level) => level.value == approval_level.level),
      })
      setMode('edit');
      setTitle('Edit Approval Level');
      setText('');
    } else {
      setApprovalLevelData({
        id: approval_level.id,
        max_amount: approval_level.max_amount,
        level: approval_level.level,
      })
      setMode('delete');
      setTitle('Delete Approval Level');
      setText('Are you sure?');
    }
  }

  const handleClose = () => {
    setOpen(false);
    setApprovalLevelData({
      id: '', max_amount: '', level: ''
    })
  };

  const handleChangeInput = (e, type) => {
    let name = type;
    let value = '';

    if (type === 'level') {
      value = e;
    } else {
      value = e.target.value;
    }

    setApprovalLevelData({ ...approvalLevelDataRef.current, [name]: value })
  }

  const SaveData = async () => {
    setLoading(true);
    try {
      if (mode === 'edit') {
        try {
          var new_data_edit = approvalLevelDataRef.current;
          var body_edit = {
            id: new_data_edit.id,
            max_amount: new_data_edit.max_amount,
            level: new_data_edit.level ? new_data_edit.level.value : '',
            status: 'published'
          }
          await directus.updateItem('approval_level', body_edit.id, body_edit);
          enqueueSnackbar("Data updated successfully", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }
      else if (mode === 'add') {
        try {
          var new_data = approvalLevelDataRef.current;
          var body = {
            max_amount: new_data.max_amount,
            level: new_data.level ? new_data.level.value : '',
            status: 'published'
          }
          await directus.createItem('approval_level', body);
          enqueueSnackbar("Data updated successfully", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
        } catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }

      }
      else {
        try {
          await directus.deleteItem('approval_level', approvalLevelDataRef.current.id);
          enqueueSnackbar("Data deleted successfully", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }
      
      removeCache('approval_level');
      loadApprovalLevel();
      setLoading(false);
      handleClose();
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
      setLoading(false);
    }
  }


  const pageChange = (value) => {
    setLoadingPagination(true);
    page = page + value;
    loadApprovalLevel();
  }

  const handleChange = (event) => {
    setPageNumber(event.target.value);
    page = 1;
    loadApprovalLevel();
  };

  const sortData = (val) => {
    setSortLoading(true);
    var sort_field_data = {
      "max_amount": { type: "desc", value: false },
      "level": { type: "desc", value: false },
    }

    var sort_data = sortFieldRef.current[val];
    if (sort_data) {
      var value = sort_data.value;
      if (sort_data.value === false) {
        value = true;
      }

      if (value === true) {

        var sort_type = "";
        if (sort_data.type === "desc") {
          sort_type = "asc";
        } else {
          sort_type = "desc";
        }

        sort_field_data[val].value = true;
        sort_field_data[val].type = sort_type;

        var sort_data = localStorage.sort_data;
        if (sort_data) {
          sort_data = JSON.parse(sort_data);
          sort_data.approval_level = sort_field_data;
        } else {
          sort_data = { approval_level: sort_field_data };
        }
        localStorage.setItem("sort_data", JSON.stringify(sort_data))
        setSortField(sort_field_data);
      }
    }

    loadApprovalLevel();
  };

  const getOptionDisabled = (option) => {
    return approvalLevel.some(
      (item) => item.level === option.value
    );
  };

  return (
    <div>
      {approvalLevel ?
        <div >
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{ backgroundColor: '#f2f2f6' }}>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                  <Grid item xs={6} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
                    <div className={classes.headerSort} onClick={() => sortData("level")}>
                      <Typography noWrap variant="h5">Level</Typography>
                      <div style={{ position: "relative" }}>
                        {sortFieldRef.current['level'].value === true ?
                          sortFieldRef.current['level'].type === "asc" ?
                            <SortRoundedIcon className={classes.ascSort} />
                            :
                            <SortRoundedIcon className={classes.descSort} />
                          :
                          ''
                        }
                      </div>
                    </div>
                  </Grid>
                    <Grid item xs={6} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
                      <div className={classes.headerSort} onClick={() => sortData("max_amount")}>
                        <Typography noWrap variant="h5">Max Amount</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['max_amount'].value === true ?
                            sortFieldRef.current['max_amount'].type === "asc" ?
                              <SortRoundedIcon className={classes.ascSort} />
                              :
                              <SortRoundedIcon className={classes.descSort} />
                            :
                            ''
                          }
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                }
              />
              <Grid item xs={2} style={{ textAlign: 'right' }}>
                <AddButton id="add_btn" size="small" color="secondary" aria-label="add" onClick={() => handleClickOpen('', 'add')}>
                  <AddIcon />
                </AddButton>
              </Grid>

            </ListItem>

          </List>
          {sortLoadingRef.current ?
            <Grid item xs={12}>
              <div className={classes.linearProgress} style={{ marginLeft: "7px", marginRight: "7px" }}><LinearProgress style={{ height: "2px" }} /></div>
            </Grid>
            : ''}
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{ marginBottom: '20px' }} >
            {approvalLevel.map((data, index) => {
              return (
                <div key={index}>
                  <ListItem style={{ backgroundColor: data.status === "draft" ? "#EBE9E9" : data.status === "inactive" ? "#cdd0d1" : data.status === "deleted" ? "#fee1e0a1" : "white" }}>
                    <ListItemText
                      primary={
                        <Grid container key={index}>
                          <Grid item xs={6} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6">{data.level ? data.level : ''}</Typography>
                          </Grid>
                          <Grid item xs={6} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6">
                              {isNaN(data.max_amount) ? 0 : '$' + formatNumber(data.max_amount.toFixed(2))}
                            </Typography>
                          </Grid>
                        </Grid>
                      }
                    />

                    <Grid item xs={2} style={{ textAlign: 'right' }}>
                      <IconButton id={`edit_btn_${index}`} aria-label="edit" onClick={() => handleClickOpen(data, 'edit')} >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton id={`delete_btn_${index}`} aria-label="delete" onClick={() => handleClickOpen(data, 'delete')} >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  </ListItem>
                  {approvalLevel.length - 1 > index ? <Divider /> : ''}
                </div>
              );
            })}
          </List>
        </div>
        :
        <CircularProgress className={classes.circular} />
      }

      <Dialog
        id="holiday_dialog"
        isopen={`${open}`}
        open={open}
        fullWidth={true}
        onClose={handleClose}
      >
        <DialogTitle disableTypography={true} id="form-dialog-title"><Typography id="holiday_dialog_title" component="div" variant="h6"><h2>{title}</h2></Typography></DialogTitle>
        <DialogContent>
          <Typography variant="h6">{text ? text : ''}</Typography>
          {mode !== 'delete' ?
            <div>
              <TextField
                id="name_text"
                label="Max Amount"
                variant="outlined"
                fullWidth={true}
                margin="normal"
                name="max_amount"
                value={approvalLevelDataRef.current.max_amount ? parseFloat(approvalLevelDataRef.current.max_amount).toFixed(2) : ''}
                onChange={(e) => {
                  handleChangeInput(e, 'max_amount');
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom
                }}
              />
              <Autocomplete
                id="size-small-standard"
                size="small"
                options={levelOptions}
                getOptionLabel={(option) => option.name}
                value={approvalLevelDataRef.current.level}
                name='level'
                isOptionEqualToValue={(option, value) => option.value == value.value}
                onChange={(event, newValue) => {
                  handleChangeInput(newValue, 'level');
                }}
                getOptionDisabled={getOptionDisabled}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    variant="outlined"
                    name="level"
                    label="Select Level"
                  />
                )}
              />
            </div>
            : ''}
        </DialogContent>
        <DialogActions style={{ height: "60px" }}>
          {loading ?
            <CircularProgress className={classes.circular} />
            :
            <div>
              <CancelButton
                id="cancel_btn"
                size="small"
                variant="contained"
                onClick={handleClose}
              >
                Cancel
              </CancelButton>
              <SubmitButton
                id="save_btn"
                disabled={mode === 'delete' ? false : disable}
                size="small"
                variant="contained"
                onClick={() => SaveData()}
                style={{ marginLeft: '10px' }}
              >
                {mode === 'delete' ? 'Delete' : 'Save'}
              </SubmitButton>
            </div>
          }

        </DialogActions>
      </Dialog>

      {approvalLevel ?
        <Grid
          item xs={12}
          style={{ textAlign: 'right', marginBottom: '10px', fontFamily: 'SF Pro' }}
        >
          <span style={{ paddingRight: '20px' }}>
            Rows per page:
          </span>
          <span style={{ paddingRight: '20px' }}>
            <TextField
              id="standard-select-currency"
              select
              value={pageNumber}
              onChange={handleChange}
            >
              {pageOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </span>

          <span style={{ paddingRight: '20px' }}>
            {(pageNumberRef.current * (page - 1)) + 1} - {(pageNumberRef.current * page) > totalPage ? totalPage : pageNumberRef.current * page} of {totalPage}
          </span>

          <Button
            variant="outlined"
            size="small"
            disabled={page === 1 || loadingPagination}
            className={classes.buttonLeft}
            onClick={() => pageChange(-1)}
          >
            <ArrowBackIosRoundedIcon />
          </Button>

          <Button
            variant="outlined"
            size="small"
            className={classes.buttonRight}
            disabled={page >= Math.ceil(totalPage / pageNumberRef.current) || loadingPagination}
            onClick={() => pageChange(1)}
          >
            <ArrowForwardIosRoundedIcon />
          </Button>
        </Grid>
        :
        " "}
    </div>
  );
}

export default ApprovalLevel;
