import DirectusSDK from '@directus/sdk-js';
import axios from 'axios';

let directus;

var company = JSON.parse(window.localStorage.getItem('company'));
var data = JSON.parse(window.localStorage.getItem('directus_data'));
var url = process.env.REACT_APP_URL;
if (window.location.hostname.search('interax') !== -1) {
  url = process.env.REACT_APP_URL_INTERAX;
}

var list;
if (data) {
  list = {
    url: url,
    project: company,
    persist: true,
    token: data.token
  };
} else {
  list = {
    url: url,
    project: company,
    persist: true,
    storage: window.localStorage
  };
}

directus = new DirectusSDK(list);

export function directusProject(company, data) {
  window.localStorage.setItem('company', JSON.stringify(company));

  directus = new DirectusSDK({
    url: url,
    project: company,
    persist: true,
    token: data.token
  });

  return directus;
}

export function changeProject(data) {
  window.localStorage.setItem('company', JSON.stringify(data));

  directus = new DirectusSDK({
    url: url,
    project: data,
    persist: true,
    storage: window.localStorage
  });

  return directus;
}

directus.batch = function (params) {
  var headers = {
    'Content-type': 'application/json;charset=UTF-8',
    Authorization: `Bearer ${directus.config.token}`
  };

  return new Promise((resolve, reject) => {
    axios
      .post(`${directus.config.url}batch.php`, params, {
        headers: headers
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

directus.items = function (collection) {
  return {
    readMany: async function (params = {}) {
      return await directus.getItems(collection, params);
    },

    readOne: async function (params = {}) {
      if (params == null) params = {};
      params.limit = 1;
      params.single = true;
      return await directus.getItems(collection, params);
    },

    read: async function (id, params = {}) {
      return await directus.getItem(collection, id, params);
    },

    createOne: async function (data) {
      return await directus.createItem(collection, data);
    },

    updateOne: async function (id, data) {
      return await directus.updateItem(collection, id, data);
    }
  };
};

export default directus;
