/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Container,
  Grid,
  Button,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Divider,
  Menu,
  IconButton,
  LinearProgress
} from '@material-ui/core';
import PageContent from 'src/components/Page';
import { enableRipple } from '@syncfusion/ej2-base';
import directus from 'src/services/directus';
import useStateRef from "react-usestateref";
import moment from 'moment';
import { CancelButton, SubmitButton } from 'src/components/ReusableStyle';
import { pageOptions } from 'src/_utils/pageOptions';

import CircularProgress from '@material-ui/core/CircularProgress';
import { useSnackbar } from 'notistack';

import AddIcon from '@material-ui/icons/Add';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

import { AdminPermission } from 'src/utils/Permission';
import { getEmployee } from 'src/utils/sessions';
import ErrorMessage from 'src/components/ErrorMessage';
import EditIcon from '@material-ui/icons/Edit';
import SortRoundedIcon from '@material-ui/icons/SortRounded';

import {
  useStyles,
  AddButton,
  RejectButton,
  ApproveButton,
  UndoButton
} from './style.js';
import linq from "linq";
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';
let page = 1;

const IndexView = () => {
  /* eslint-disable no-unused-vars*/
  const classes = useStyles();
  const [initialized, setInitialized] = useState(false);
  const [totalPage, setTotalPage, totalPageRef] = useStateRef("");
  const [pageNumber, setPageNumber, pageNumberRef] = useStateRef(100);

  const [dataSupplier, setDataSupplier, dataSupplierRef] = useStateRef({
    id: '', code: '', name: '', email: '', phone: ''
  })

  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingPagination, setLoadingPagination] = useState(false);


  const [suppliers, setSuppliers] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filter, setFilter, filterRef] = useStateRef(1);

  const [mode, setMode] = useState('');

  const [sortField, setSortField, sortFieldRef] = useStateRef({
    "name": { type: "desc", value: false },
    "email": { type: "desc", value: false },
    "created_on": { type: "desc", value: false },
    "requester": { type: "desc", value: false },
    "status": { type: "desc", value: false }
  });

  const [sortLoading, setSortLoading, sortLoadingRef] = useStateRef(false);


  /* eslint-enable no-unused-vars*/

  enableRipple(true);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadFirst();
    }
  }, []);

  const loadFirst = () => {
    var sort_data = localStorage.sort_data;
    if (sort_data) {
      sort_data = JSON.parse(sort_data);
      if (sort_data.supplier_requests) {
        setSortField(sort_data.supplier_requests)
      }
    }
    loadSuppliers();
  }

  const loadSuppliers = async () => {
    var name_sort = sortFieldRef.current.name;
    var email_sort = sortFieldRef.current.email;
    var created_on_sort = sortFieldRef.current.created_on;
    var requester_sort = sortFieldRef.current.requester;
    var status_sort = sortFieldRef.current.status;

    var orderByField = "-suppliers.created_on";

    if (name_sort.value) {
      if (name_sort.type === "asc") { orderByField = "suppliers.name"; }
      else if (name_sort.type === "desc") { orderByField = "-suppliers.name"; }
    }

    if (email_sort.value) {
      if (email_sort.type === "asc") { orderByField = "suppliers.email"; }
      else if (email_sort.type === "desc") { orderByField = "-suppliers.email"; }
    }

    if (created_on_sort.value) {
      if (created_on_sort.type === "asc") { orderByField = "suppliers.created_on"; }
      else if (created_on_sort.type === "desc") { orderByField = "-suppliers.created_on"; }
    }

    if (requester_sort.value) {
      if (requester_sort.type === "asc") { orderByField = "owner"; }
      else if (requester_sort.type === "desc") { orderByField = "-owner"; }
    }

    var owner = { "nnull": null };
    var employee_id = await getEmployee();
    var status = { "in": ['draft', 'rejected'] };

    if (!AdminPermission()) {
      owner = { "eq": employee_id.user.id };
      status = { "in": ['draft', 'rejected', 'published'] };
    }

    if (filterRef.current !== 1) {
      var filter_status = "";
      if (filterRef.current === 2) {
        filter_status = "draft";
      } else if (filterRef.current === 3) {
        filter_status = "rejected";
      } else if (filterRef.current === 4) {
        filter_status = "published";
      }

      status = { "in": [filter_status] }
    }

    var result = await directus.api.get('/custom/settings/suppliers', {
      fields: "*,owner.*",
      filter: {
        'suppliers.status': status,
        owner: owner
      },
      meta: "filter_count",
      limit: pageNumberRef.current,
      page: page,
      sort: orderByField
    });

    setSuppliers(result.data);
    setTotalPage(result.meta.filter_count);

    let SortOrder;
    let sort_type = '';
    let sort_by_data = '';
    let changeUpperCase = false;

    if (name_sort.value) {
      sort_by_data = 'name';
      sort_type = name_sort.type;
      changeUpperCase = true;
    } else if (email_sort.value) {
      sort_by_data = 'email';
      sort_type = email_sort.type;
      changeUpperCase = true;
    } else if (requester_sort.value) {
      sort_by_data = 'email_owner';
      sort_type = requester_sort.type;
      changeUpperCase = true;
    } else if (status_sort.value) {
      sort_by_data = 'status';
      sort_type = status_sort.type;
      changeUpperCase = true;
    } else {
      sort_by_data = 'name';
      sort_type = 'asc';
      changeUpperCase = true;
    }

    SortOrder = result.data;
    SortOrder.map((value, index) => {
      var email_owner = "";
      if (value.owner !== null) {
        if (value.owner.email) {
          email_owner = value.owner.email;
        }
      }
      SortOrder[index].email_owner = email_owner;
    })

    SortOrder = linq.from(SortOrder)
      .orderByDescending(o => o.created_on)
      .toArray();

    if (sort_by_data) {
      if (sort_type === "desc") {
        SortOrder.sort(function (a, b) {
          let nameA = a[sort_by_data] ? a[sort_by_data] : '';
          let nameB = b[sort_by_data] ? b[sort_by_data] : '';
          if (changeUpperCase) {
            nameA = nameA ? nameA.toUpperCase() : nameA; // ignore upper and lowercase
            nameB = nameB ? nameB.toUpperCase() : nameB; // ignore upper and lowercase
          }

          if (nameA > nameB) { return -1; }
          if (nameA < nameB) { return 1; }
          return 0; // names must be equal
        });
      } else {
        SortOrder.sort(function (a, b) {
          let nameA = a[sort_by_data] ? a[sort_by_data] : '';
          let nameB = b[sort_by_data] ? b[sort_by_data] : '';
          if (changeUpperCase) {
            nameA = nameA ? nameA.toUpperCase() : nameA; // ignore upper and lowercase
            nameB = nameB ? nameB.toUpperCase() : nameB; // ignore upper and lowercase
          }

          if (nameA < nameB) { return -1; }
          if (nameA > nameB) { return 1; }
          return 0; // names must be equal
        });
      }
    }

    if (SortOrder) {
      setSuppliers(SortOrder);
    }
    setSortLoading(false);
  }

  const pageChange = (value) => {
    setLoadingPagination(true);
    page = page + value;
    loadSuppliers();
  }

  const handleChange = (event) => {
    setPageNumber(event.target.value);
    page = 1;
    loadSuppliers();
  };

  const handleClose = () => {
    setOpen(false);
    setDataSupplier({ id: '', code: '', name: '', email: '', phone: '' })
  };

  const handleClickOpen = (value, mode_status) => {
    setMode(mode_status);
    if (mode_status === "edit") {
      setDataSupplier({ id: value.id, code: value.code, name: value.name, email: value.email, phone: value.phone_number })
    } else if (mode_status === "add") {
      setDataSupplier({ id: '', code: '', name: '', email: '', phone: '' })
    }
    setOpen(true);

  }

  const handleChangeInput = (e, type) => {
    const { name, value } = e.target;
    if (type === "email") {
      var check_value = null;
      if (value) {
        if (value === " ") {
          check_value = "";
        } else {
          check_value = value.split(/[ ,]+/).join(';');
          check_value = check_value.split(/[;;,]+/).join(';');
        }
      }
      setDataSupplier({ ...dataSupplierRef.current, [name]: check_value })
    } else {
      setDataSupplier({ ...dataSupplierRef.current, [name]: value })
    }
  }

  const SaveData = async () => {
    setLoading(true);
    try {
      var new_data = dataSupplierRef.current;

      var body = {
        code: new_data.code,
        name: new_data.name,
        email: new_data.email,
        phone_number: new_data.phone,
        status: 'draft'
      }

      if (mode === "edit") {
        await directus.updateItem('suppliers', new_data.id, body);
      } else if (mode === "add") {
        await directus.api.post('/items/suppliers', body, { supplier_request: true });
      }

      enqueueSnackbar("Data updated successfully", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }

    loadSuppliers();
    setLoading(false);
    handleClose();
  }

  const updateSupplier = async (supplier, status) => {
    setLoadingUpdate(true);
    await directus.updateItem('suppliers', supplier.id, { status: status });

    if (status !== "draft") {

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + directus.api.auth.config.token },
        body: JSON.stringify({
          supplier_id: supplier.id,
          status: status === "published" ? "approved" : status,
        })
      };

      var react_app_url = process.env.REACT_APP_URL;
      if (window.location.hostname.search('interax') !== -1) {
        react_app_url = process.env.REACT_APP_URL_INTERAX;
      }

      let dataURL = `${react_app_url}${JSON.parse(window.localStorage.getItem('company'))}/custom/orders/send_supplier_notification`;

      try {
        await fetch(dataURL, requestOptions)
          .then(resp => {
            if (resp.status) {
              if (resp.status === 200) {
                enqueueSnackbar("The supplier data has been successfully updated \nand the requester will get a notification email.", { style: { whiteSpace: 'pre-line' }, anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
                EventEmitter.emit("loadSupplier", { value: true })
              }
              else {
                enqueueSnackbar(resp.statusText, { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
              }
            } else {
              enqueueSnackbar(ErrorMessage(400), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
            }
          }
          )
      } catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
      }
    } else {
      enqueueSnackbar("The supplier data has been successfully updated.", { style: { whiteSpace: 'pre-line' }, anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
    }

    setLoadingUpdate(false);
    loadSuppliers();
  }

  const handleCloseStatus = () => {
    setAnchorEl(null);
  };

  function filterStatus(input) {
    setFilter(input);
    loadSuppliers();
    handleCloseStatus();
  }

  let disable = dataSupplierRef.current.name === '';

  const sortData = (val) => {
    setSortLoading(true);
    var sort_field_data = {
      "name": { type: "desc", value: false },
      "email": { type: "desc", value: false },
      "created_on": { type: "desc", value: false },
      "requester": { type: "desc", value: false },
      "status": { type: "desc", value: false }
    }

    var sort_data = sortFieldRef.current[val];
    if (sort_data) {
      var value = sort_data.value;
      if (sort_data.value === false) {
        value = true;
      }

      if (value === true) {

        var sort_type = "";
        if (sort_data.type === "desc") {
          sort_type = "asc";
        } else {
          sort_type = "desc";
        }

        sort_field_data[val].value = true;
        sort_field_data[val].type = sort_type;

        var sort_data = localStorage.sort_data;
        if (sort_data) {
          sort_data = JSON.parse(sort_data);
          sort_data.supplier_requests = sort_field_data;
        } else {
          sort_data = { supplier_requests: sort_field_data };
        }
        localStorage.setItem("sort_data", JSON.stringify(sort_data))
        setSortField(sort_field_data);
      }
    }

    loadSuppliers();
  }

  return (
    <PageContent title="Supplier Requests" style={{ paddingTop: "0px" }}>
      <Container maxWidth={false} style={{ padding: "0px", marginLeft: "28px", paddingRight: "28px" }}>
        <Typography variant="h4" className={classes.title} id="title">
          Supplier Requests
        </Typography>
        {suppliers ?
          <div>
            <List component="nav" aria-label="main" className={classes.root} style={{ backgroundColor: '#f2f2f6', marginTop: "10px", marginRight: "28px" }}>
              <ListItem>
                <ListItemText
                  primary={
                    <Grid container>
                      <Grid item xs={!AdminPermission() ? 4 : 3} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
                        <div className={classes.headerSort} onClick={() => sortData("name")}>
                          <Typography noWrap variant="h5">Name</Typography>
                          <div style={{ position: "relative" }}>
                            {sortFieldRef.current['name'].value === true ?
                              sortFieldRef.current['name'].type === "asc" ?
                                <SortRoundedIcon className={classes.ascSort} />
                                :
                                <SortRoundedIcon className={classes.descSort} />
                              :
                              ''
                            }
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={!AdminPermission() ? 3 : 3} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
                        <div className={classes.headerSort} onClick={() => sortData("email")}>
                          <Typography noWrap variant="h5">Email</Typography>
                          <div style={{ position: "relative" }}>
                            {sortFieldRef.current['email'].value === true ?
                              sortFieldRef.current['email'].type === "asc" ?
                                <SortRoundedIcon className={classes.ascSort} />
                                :
                                <SortRoundedIcon className={classes.descSort} />
                              :
                              ''
                            }
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={2} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
                        <div className={classes.headerSort} onClick={() => sortData("created_on")}>
                          <Typography noWrap variant="h5">Created On</Typography>
                          <div style={{ position: "relative" }}>
                            {sortFieldRef.current['created_on'].value === true ?
                              sortFieldRef.current['created_on'].type === "asc" ?
                                <SortRoundedIcon className={classes.ascSort} />
                                :
                                <SortRoundedIcon className={classes.descSort} />
                              :
                              ''
                            }
                          </div>
                        </div>
                      </Grid>
                      {AdminPermission() ?
                        <Grid item xs={3} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
                          <div className={classes.headerSort} onClick={() => sortData("requester")}>
                            <Typography noWrap variant="h5">Requester</Typography>
                            <div style={{ position: "relative" }}>
                              {sortFieldRef.current['requester'].value === true ?
                                sortFieldRef.current['requester'].type === "asc" ?
                                  <SortRoundedIcon className={classes.ascSort} />
                                  :
                                  <SortRoundedIcon className={classes.descSort} />
                                :
                                ''
                              }
                            </div>
                          </div>
                        </Grid>
                        : ''}
                      <Grid item xs={1} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
                        {/* <Typography variant="h5">Status</Typography> */}
                        <Button
                          size="small"
                          onClick={(e) => setAnchorEl(e.currentTarget)}
                          style={{ paddingLeft: '0px' }}
                        >
                          <Typography variant="h5" style={{ textTransform: 'capitalize' }} noWrap >Status</Typography><i className="fa fa-filter fa-lg"></i>
                        </Button>
                        <Menu
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleCloseStatus}
                          className={classes.filter}
                        >
                          <MenuItem onClick={() => filterStatus(1)} selected={1 === filter} >All</MenuItem>
                          <MenuItem onClick={() => filterStatus(2)} selected={2 === filter} >Draft</MenuItem>
                          <MenuItem onClick={() => filterStatus(3)} selected={3 === filter} >Rejected</MenuItem>
                          {!AdminPermission() ?
                            <MenuItem onClick={() => filterStatus(4)} selected={4 === filter} >Approved</MenuItem>
                            : ''}
                        </Menu>
                      </Grid>
                    </Grid>
                  }
                />
                <Grid item xs={!AdminPermission() ? 1 : 2} style={{ textAlign: 'right' }}>
                  <AddButton id="add_btn" size="small" color="secondary" aria-label="add"
                    onClick={() => handleClickOpen('', 'add')}
                  >
                    <AddIcon />
                  </AddButton>
                </Grid>
              </ListItem>

              {sortLoadingRef.current ?
                <Grid item xs={12}>
                  <div className={classes.linearProgress} style={{ marginLeft: "7px", marginRight: "7px" }}><LinearProgress style={{ height: "2px" }} /></div>
                </Grid>
                : ''}
              <List component="nav" aria-label="main" className={classes.listitem} dense={true} style={{ marginBottom: '20px' }} >
                {suppliers.map((supplier, index) => {
                  return (
                    <div key={index}>
                      <ListItem style={{ backgroundColor: supplier.status === "draft" ? "#ebe9e9" : supplier.status === "rejected" ? "#FFDBD9" : supplier.status === "published" ? "#D0F2D4" : "" }}>
                        <ListItemText
                          primary={
                            <Grid container key={index}>
                              <Grid item xs={!AdminPermission() ? 4 : 3} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                                <Typography variant="h6">{supplier.name ? supplier.name : ''}</Typography>
                              </Grid>
                              <Grid item xs={!AdminPermission() ? 3 : 3} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                                <Typography variant="h6">{supplier.email ? supplier.email : ''}</Typography>
                              </Grid>
                              <Grid item xs={2} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                                <Typography variant="h6">{supplier.created_on ? moment(supplier.created_on).format("DD-MM-YYYY") : ''}</Typography>
                              </Grid>
                              {AdminPermission() ?
                                <Grid item xs={3} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                                  <Typography variant="h6">{supplier.owner ? supplier.owner.email : ''}</Typography>
                                </Grid>
                                : ''}
                              <Grid item xs={1} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                                <Typography variant="h6" style={{ textTransform: "capitalize" }}>{supplier.status ? supplier.status === "published" ? "Approved" : supplier.status : ''}</Typography>
                              </Grid>
                            </Grid>
                          }
                        />
                        {!AdminPermission() ?
                          <Grid item xs={1} style={{ textAlign: 'right' }}>
                            {supplier.status === "draft" ?
                              <IconButton style={{ padding: "8px" }} aria-label="edit" onClick={() => handleClickOpen(supplier, 'edit')} >
                                <EditIcon fontSize="small" />
                              </IconButton>
                              : ''}
                          </Grid>
                          :
                          <Grid item xs={2} style={{ textAlign: 'right' }}>
                            {supplier.status === "draft" ?
                              <div style={{ marginLeft: "-25px" }}>
                                <IconButton style={{ padding: "8px" }} aria-label="edit" onClick={() => handleClickOpen(supplier, 'edit')} >
                                  <EditIcon fontSize="small" />
                                </IconButton>

                                <RejectButton
                                  name="rejected"
                                  variant="contained"
                                  component="span"
                                  disabled={loadingUpdate}
                                  onClick={() => updateSupplier(supplier, "rejected")}
                                >
                                  REJECT
                                </RejectButton>

                                <ApproveButton
                                  name="approve"
                                  variant="contained"
                                  component="span"
                                  disabled={loadingUpdate}
                                  onClick={() => updateSupplier(supplier, "published")}
                                >
                                  Approve
                                </ApproveButton>

                              </div>
                              :
                              supplier.status === "rejected" ?
                                <>
                                  <UndoButton
                                    name="undo"
                                    variant="contained"
                                    component="span"
                                    disabled={loadingUpdate}
                                    onClick={() => updateSupplier(supplier, "draft")}
                                  >
                                    Undo Reject
                                  </UndoButton>
                                </>
                                : ""
                            }
                          </Grid>
                        }
                      </ListItem>
                      {suppliers.length - 1 > index ? <Divider /> : ''}
                    </div>
                  );
                })}

              </List>

              <Dialog
                isopen={`${open}`}
                open={open}
                fullWidth={true}
                onClose={handleClose}
              >
                <DialogTitle disableTypography={true} id="form-dialog-title"><Typography component="div" variant="h6"><h2>New Supplier Request</h2></Typography></DialogTitle>
                <DialogContent>
                  <div>
                    {mode === "edit" ?
                      <TextField
                        id="code_text"
                        label="Code"
                        variant="outlined"
                        fullWidth={true}
                        margin="normal"
                        name="code"
                        value={dataSupplierRef.current.code}
                        onChange={handleChangeInput}
                      />
                      : ''
                    }
                    <TextField
                      id="name_text"
                      label="Name"
                      variant="outlined"
                      fullWidth={true}
                      margin="normal"
                      name="name"
                      value={dataSupplierRef.current.name}
                      onChange={handleChangeInput}
                    />
                    <TextField
                      id="email_text"
                      label="Email"
                      variant="outlined"
                      fullWidth={true}
                      margin="normal"
                      name="email"
                      value={dataSupplierRef.current.email}
                      onChange={(e) => handleChangeInput(e, "email")}
                    />
                    <TextField
                      id="phone_text"
                      label="Phone"
                      variant="outlined"
                      fullWidth={true}
                      margin="normal"
                      name="phone"
                      value={dataSupplierRef.current.phone}
                      onChange={handleChangeInput}
                    />
                  </div>
                </DialogContent>
                <DialogActions style={{ height: "60px" }}>
                  {loading ?
                    <CircularProgress className={classes.circular} />
                    :
                    <div>
                      <CancelButton
                        id="cancel_btn"
                        size="small"
                        variant="contained"
                        onClick={handleClose}
                      >
                        Cancel
                      </CancelButton>
                      <SubmitButton
                        id="save_btn"
                        disabled={disable}
                        size="small"
                        variant="contained"
                        onClick={() => SaveData()}
                      >
                        Save
                      </SubmitButton>
                    </div>
                  }

                </DialogActions>
              </Dialog>
            </List>
          </div>
          : <CircularProgress className={classes.circular} />}

        {suppliers ?
          <Grid
            item xs={12}
            style={{ textAlign: 'right', marginBottom: '10px', fontFamily: 'SF Pro', paddingRight: "28px" }}
          >
            <span style={{ paddingRight: '20px' }}>
              Rows per page:
            </span>
            <span style={{ paddingRight: '20px' }}>
              <TextField
                id="standard-select-currency"
                select
                value={pageNumber}
                onChange={handleChange}
              >
                {pageOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </span>

            <span style={{ paddingRight: '20px' }}>
              {(pageNumberRef.current * (page - 1)) + 1} - {(pageNumberRef.current * page) > totalPage ? totalPage : pageNumberRef.current * page} of {totalPage}
            </span>

            <Button
              variant="outlined"
              size="small"
              disabled={page === 1 || loadingPagination}
              className={classes.buttonLeft}
              onClick={() => pageChange(-1)}
            >
              <ArrowBackIosRoundedIcon />
            </Button>

            <Button
              variant="outlined"
              size="small"
              className={classes.buttonRight}
              disabled={page >= Math.ceil(totalPage / pageNumberRef.current) || loadingPagination}
              onClick={() => pageChange(1)}
            >
              <ArrowForwardIosRoundedIcon />
            </Button>
          </Grid>
          :
          " "}
      </Container>

    </PageContent >
  );
};

export default IndexView;