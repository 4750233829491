import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: "10px"
  },

  buttonLeft: {
    backgroundColor: 'white',
    padding: 0,
    width: '60px',
    borderRadius: '24px 0 0 24px',
    borderRight: 0,
    boxShadow: '0 0 black',
    height: 30,
    border: '0 !important',
  },
  buttonRight: {
    backgroundColor: 'white',
    padding: 0,
    width: '60px',
    borderRadius: '0 24px 24px 0',
    boxShadow: '0 0 black',
    height: 30,
    border: '0 !important',
  },
  tablehead: {
    borderRadius: '8px',
    height: '38px',
    padding: '9px 0px 6px 0px',
    marginBottom: '10px',
    position: 'sticky',
    top: '12px',
  },
  circular: {
    color: '#5bfa17',
    marginLeft: '45%'
  },
  buttonText: {
    backgroundColor: 'white',
    padding: 0,
    borderRight: 0,
    boxShadow: '0 0 black',
    border: 0,
    height: 30,
    marginTop: '0px',
    borderRadius: '0px'
  },
  headerSort: {
    display: "flex",
    overflowX: "hidden",
    cursor: "pointer"
  },
  ascSort: {
    color: "#516573",
    fontSize: "20px",
    position: "absolute",
    marginTop: "1px",
    marginLeft: "3px",
    transform: "rotateX(180deg)"
  },
  descSort: {
    color: "#516573",
    fontSize: "20px",
    position: "absolute",
    marginTop: "1px",
    marginLeft: "3px",
  }
}));


export const useStyles3 = makeStyles((theme) => ({
  textInput: {
    border: "1px solid #cfc6c6",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    borderRadius: "6px",
    fontSize: "14px",
    cursor: "pointer",
    height: "30px",
    paddingLeft: "10px",
    paddingRight: "6px",
    alignItems: "center",
    "&:hover": {
      border: "1px solid black",
    }
  },
  textInput2: {
    border: "1px solid #cfc6c6",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    borderRadius: "6px",
    fontSize: "14px",
    cursor: "pointer",
    height: "30px",
    paddingLeft: "10px",
    paddingRight: "6px",
    alignItems: "center",
    "&:hover": {
      border: "1px solid black",
    },
    background: "white",
    marginRight: "6px",
    marginBottom: "6px"
  },
  textInputValue: {
    overflowY: "hidden",
    width: "calc(100% - 20px)",
    fontWeight: "400",
    textOverflow: "ellipsis",
    overflowX: "hidden",
    whiteSpace: "nowrap"
  },
  arrowDropDown: {
    fontSize: "18px",
    height: "24px !important",
    color: "rgba(0, 0, 0, 0.54)",
    marginTop: "2px",
    "&:hover": {
      borderRadius: "50%",
      background: "#a19c9c1a"
    }
  }

}));

export default useStyles3;