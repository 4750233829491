import { getEmployee } from 'src/utils/sessions';
import { InvoiceAdminPermission } from 'src/utils/Permission';
import { getCache, setCache } from 'src/utils/KeyValueCache';
import Enumerable from 'linq';
import directus from '../../services/directus';

export const getInvoiceApprovers = async (invoice_data,) => {
  try {
    var invoice_approvals_level = 1;
    var is_approver_level_1 = false;
    var is_approver_level_2 = false;
    var is_approver_admin = false;


    //get invoice - customer data
    var customer_id = null;
    var raw_customer = null;
    if (invoice_data.orders) {
      if (invoice_data.orders[0]) {
        if (invoice_data.orders[0].orders_id) {
          if (invoice_data.orders[0].orders_id.farm_code) {
            customer_id = invoice_data.orders[0].orders_id.farm_code.id;
          }
        }
      }
    }

    if (!customer_id && invoice_data.farm_code) {
      customer_id = invoice_data.farm_code.id;
    }

    if (!customer_id) {
      raw_customer = invoice_data.raw_customer;
    }

    if (customer_id || raw_customer) {

      //get current user - employee id
      var employee_id = await getEmployee();

      var filter_data = { employee: { in: employee_id.id } };
      filter_data = {};

      var approval_level1_cache_key = 'customers_approvers_level1';
      var approval_level2_cache_key = 'customers_approvers_level2';

      var approval_level1_cache = getCache(approval_level1_cache_key);
      var approval_level2_cache = getCache(approval_level2_cache_key);
      var batch_body = {};

      if (!approval_level1_cache) {
        batch_body['customers_approvers_level1'] = {
          fields: 'customer.id, customer.name, employee.id',
          limit: -1
        };
      }

      if (!approval_level2_cache) {
        batch_body['customers_approvers_level2'] = {
          fields: 'customer.id, customer.name, employee.id',
          limit: -1
        };
      }

      //get list of customers approvers level 1 and 2
      var batch_result = {};
      if (Object.keys(batch_body).length) {
        batch_result = await directus.api.post('/custom/batch', batch_body);
      }

      var po_customer_employees = { data: [] };
      if (!approval_level1_cache) {
        if (batch_result.data.customers_approvers_level1) {
          po_customer_employees = { data: batch_result.data.customers_approvers_level1 };
        }

        setCache(approval_level1_cache_key, batch_result.data.customers_approvers_level1);
      } else {
        po_customer_employees = { data: approval_level1_cache };
      }

      var po_customer_employees2 = { data: [] };
      if (!approval_level2_cache) {
        if (batch_result.data.customers_approvers_level2) {
          po_customer_employees2 = { data: batch_result.data.customers_approvers_level2 };
        }

        setCache(approval_level2_cache_key, batch_result.data.customers_approvers_level2);
      } else {
        po_customer_employees2 = { data: approval_level2_cache };
      }

      if (customer_id) {
        po_customer_employees.data = po_customer_employees.data.filter(f => f.customer && f.customer.id == customer_id);
        po_customer_employees2.data = po_customer_employees2.data.filter(f => f.customer && f.customer.id == customer_id);
      } else if (raw_customer) {
        po_customer_employees.data = po_customer_employees.data.filter(f => f.customer && f.customer.name == raw_customer);
        po_customer_employees2.data = po_customer_employees2.data.filter(f => f.customer && f.customer.name == raw_customer);
      }

      if (po_customer_employees.data.length > 0 || po_customer_employees2.data.length > 0) {
        //check if invoice need 2 level of approvals
        if (po_customer_employees2.data.length > 0) {
          invoice_approvals_level = 2;
        }

        var po_customer_id = [];
        var po_customer_id2 = [];

        // check if current employee is approvel level 1
        if (po_customer_employees.data.length > 0) {
          po_customer_id = Enumerable.from(po_customer_employees.data)
            .where(w => w.employee.id == employee_id.id)
            .select(data => data.customer.id)
            .toArray();

          if (po_customer_id.length > 0) {
            is_approver_level_1 = true;
          }
        }


        // check if current employee is approvel level 1
        if (po_customer_employees2.data.length > 0) {
          po_customer_id2 = Enumerable.from(po_customer_employees2.data)
            .where(w => w.employee.id == employee_id.id)
            .select(data => data.customer.id)
            .toArray();

          if (po_customer_id2.length > 0) {
            is_approver_level_2 = true;
          }
        }
      }
    }

    if (InvoiceAdminPermission()) {
      is_approver_admin = true;
    }

  } catch (e) {

  }

  return {
    invoice_approvals_level: invoice_approvals_level,
    is_approver_level_1: is_approver_level_1,
    is_approver_level_2: is_approver_level_2,
    is_approver_admin: is_approver_admin
  };

};